import Link from 'next/link';
import classNames from 'classnames';

import { Svg } from '@components/svg/Svg';

import { ColorField } from '@interfaces';

import styles from './navigation-anchor-primary.module.scss';

interface NavigationAnchorPrimaryProps {
	backgroundColor?: ColorField;
	isActive?: boolean;
	url: string;
	children?: React.ReactNode;
}

const getStyle = (
	backgroundColor: ColorField | undefined,
): React.CSSProperties | undefined => {
	if (backgroundColor?.hex) {
		return {
			'--background-color': backgroundColor.hex,
		} as React.CSSProperties;
	}

	return undefined;
};

export const NavigationAnchorPrimary = ({
	backgroundColor,
	children,
	isActive = false,
	url,
}: NavigationAnchorPrimaryProps) => {
	const style = getStyle(backgroundColor);

	return (
		<Link
			className={classNames(styles['navigation-anchor-primary'], {
				[styles['navigation-anchor-primary--is-active']]: isActive,
			})}
			href={url}
			style={style}
		>
			<span className={styles['navigation-anchor-primary__label']}>
				{children}
				<Svg
					aria-hidden="true"
					className={styles['navigation-anchor-primary__arrow']}
					id="hand-drawn-arrow-5"
				/>
			</span>
		</Link>
	);
};
