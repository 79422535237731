import { useEffect, useState } from 'react';

// Makes sure playing videos are stopped/started again when the page visibility changes
export const useVideoVisibility = () => {
	const [hiddenVideoElements, setHiddenVideoElements] = useState<
		HTMLVideoElement[]
	>([]);

	const handleVisibilityChange = () => {
		const { visibilityState } = document;

		if (visibilityState === 'hidden') {
			const videoElements = Array.from(document.querySelectorAll('video'));

			const videoElementsWithSrc = videoElements.filter((videoElement) => {
				return videoElement.hasAttribute('src');
			});

			setHiddenVideoElements(videoElementsWithSrc);

			videoElementsWithSrc.forEach((videoElement) => {
				const src = videoElement.getAttribute('src');

				if (src) {
					videoElement.removeAttribute('src');
					videoElement.setAttribute('data-src', src);
				}
			});
		}

		if (visibilityState === 'visible' && hiddenVideoElements.length) {
			hiddenVideoElements.forEach((videoElement) => {
				const dataSrc = videoElement.getAttribute('data-src');

				if (dataSrc) {
					videoElement.removeAttribute('data-src');
					videoElement.setAttribute('src', dataSrc);
				}
			});

			setHiddenVideoElements([]);
		}
	};

	useEffect(() => {
		if (document.visibilityState) {
			document.addEventListener(
				'visibilitychange',
				handleVisibilityChange,
				false,
			);

			return () => {
				document.removeEventListener(
					'visibilitychange',
					handleVisibilityChange,
					false,
				);
			};
		}
	}, []);
};
