import Link from 'next/link';
import classNames from 'classnames';

import { MembershipAnchor } from '@components/membershipAnchor/MembershipAnchor';
import { Svg } from '@components/svg/Svg';

import { useUser } from '@context/UserContext';

import styles from './navigation-fullscreen-header.module.scss';
import { NavigationAddOn } from './NavigationAddOn';

interface NavigationFullscreenHeaderProps {
	className?: string;
}

export const NavigationFullscreenHeader = ({
	className,
}: NavigationFullscreenHeaderProps) => {
	const { authUser } = useUser();
	return (
		<div
			className={classNames(className, styles['navigation-fullscreen-header'])}
		>
			<ul
				className={classNames(
					styles['navigation-fullscreen-header__anchor-list'],
					'list-unstyled',
				)}
			>
				<li className={styles['navigation-fullscreen-header__logo']} key="logo">
					<Link aria-label="Homepage" href="/">
						<Svg id="logo" />
					</Link>
				</li>
				<li
					className={classNames(
						styles['navigation-fullscreen-header__membership-anchor'],
						{
							[styles[
								'navigation-fullscreen-header__membership-anchor--logged-in'
							]]: authUser,
						},
					)}
					key="membership-anchor"
				>
					<MembershipAnchor url="/membership">Membership</MembershipAnchor>
				</li>
				<li
					className={styles['navigation-fullscreen-header__account']}
					key="account"
				>
					<NavigationAddOn />
				</li>
			</ul>
		</div>
	);
};
