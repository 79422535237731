import { RefObject, useEffect } from 'react';

const CANDIDATES = `
	a, button, input, select, textarea, svg, area, details, summary,
	iframe, object, embed,
	[tabindex], [contenteditable]
`;

export const useFocusTrap = (
	ref: RefObject<HTMLElement>,
	dependsOn: boolean,
): void => {
	useEffect(() => {
		if (dependsOn) {
			const nodes = Array.from(document.querySelectorAll(CANDIDATES)).filter(
				(node) =>
					ref.current &&
					!ref.current.contains(node) &&
					node.getAttribute('tabindex') !== '-1',
			);
			nodes.forEach((node) => node.setAttribute('tabindex', '-1'));
			return () => {
				nodes.forEach((node) => node.removeAttribute('tabindex'));
			};
		}
	}, [dependsOn]);
};
