/* eslint-disable camelcase */
import { createBrowserSupabaseClient } from '@supabase/auth-helpers-nextjs';
import {
	PostgrestMaybeSingleResponse,
	PostgrestResponse,
	PostgrestSingleResponse,
} from '@supabase/supabase-js';

import {
	SupabaseProductRecordWithPrices,
	SupabaseUserRecord,
} from '@interfaces';

const supabaseClient = createBrowserSupabaseClient();

interface UpdateUserRecordParams {
	userId: string;
	formData: FormData;
	userRecord: SupabaseUserRecord | null;
}

export const updateUserRecord = async ({
	userId,
	formData,
	userRecord,
}: UpdateUserRecordParams): Promise<
	PostgrestSingleResponse<SupabaseUserRecord>
> => {
	let update: Partial<SupabaseUserRecord> | null = null;

	const full_name = formData.get('full_name');

	if (full_name && userRecord) {
		const hasFullNameChanged = full_name !== userRecord.full_name;
		const isFullNameChangeAllowed = userRecord.remaining_name_changes > 0;

		if (hasFullNameChanged && isFullNameChangeAllowed) {
			update = {
				full_name: full_name.toString(),
				remaining_name_changes: userRecord.remaining_name_changes - 1,
			};
		}
	}

	if (full_name && !userRecord) {
		// On email sign-up `userRecord` not yet set: we should not alter `remaining_name_changes`.
		update = {
			full_name: full_name.toString(),
		};
	}

	if (update) {
		return supabaseClient
			.from('users')
			.update(update)
			.eq('id', userId)
			.select('*')
			.single();
	}

	return supabaseClient.from('users').select('*').eq('id', userId).single();
};

export const getUserRecord = async (): Promise<
	PostgrestSingleResponse<SupabaseUserRecord>
> => {
	// As we’re using row level security only the logged in user’s record is returned
	return supabaseClient.from('users').select('*').single();
};

export const getProductsWithPrices = async (): Promise<
	PostgrestResponse<SupabaseProductRecordWithPrices>
> => {
	return supabaseClient
		.from('products')
		.select('*, prices(*)')
		.eq('active', true)
		.eq('prices.active', true)
		.order('metadata->position');
};

export const getProductWithPrices = async (
	slug: string,
): Promise<PostgrestMaybeSingleResponse<SupabaseProductRecordWithPrices>> => {
	return supabaseClient
		.from('products')
		.select('*, prices(*)')
		.eq('slug', slug)
		.eq('active', true)
		.eq('prices.active', true)
		.maybeSingle();
};
