// https://kentcdodds.com/blog/listify-a-java-script-array
// unfortunately TypeScript doesn't have Intl.ListFormat yet 😢
// so we'll just add it ourselves:
type ListFormatOptions = {
	type?: 'conjunction' | 'disjunction' | 'unit';
	style?: 'long' | 'short' | 'narrow';
	localeMatcher?: 'lookup' | 'best fit';
};

// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace Intl {
	class ListFormat {
		constructor(locale: string, options: ListFormatOptions);

		public format: (items: Array<string>) => string;
	}
}

type ListifyOptions<ItemType> = {
	type?: ListFormatOptions['type'];
	style?: ListFormatOptions['style'];
	stringify?: (item: ItemType) => string;
};

export const listify = <ItemType extends { toString(): string }>(
	array: Array<ItemType>,
	{
		type = 'conjunction',
		style = 'long',
		stringify = (thing: { toString(): string }) => thing.toString(),
	}: ListifyOptions<ItemType> = {},
) => {
	if ('ListFormat' in Intl) {
		const stringified = array.map((item) => stringify(item));
		const formatter = new Intl.ListFormat('en-US', { style, type });
		return formatter.format(stringified);
	}

	// Fallback that mainly exists for Safari browsers:
	// https://caniuse.com/mdn-javascript_builtins_intl_listformat_format
	return array.reduce((list, item, index) => {
		if (index === 0) return stringify(item);
		if (index === array.length - 1) {
			if (index === 1) return `${list} and ${stringify(item)}`;
			return `${list}, and ${stringify(item)}`;
		}

		return `${list}, ${stringify(item)}`;
	}, '');
};
