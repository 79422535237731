import classNames from 'classnames';

import { Svg } from '@components/svg/Svg';

import { ColorField } from '@interfaces';

import styles from './map-drawing.module.scss';

interface MapDrawingProps {
	highlightColor?: ColorField;
	className?: string;
	mapLinkUrl: string;
	mapLinkLabel: string;
}

export const MapDrawing = ({
	highlightColor,
	className,
	mapLinkUrl,
	mapLinkLabel,
}: MapDrawingProps) => {
	const customProperties = {
		'--highlight-color': highlightColor?.hex,
	} as React.CSSProperties;
	return (
		<div className={classNames(className, styles['map-drawing'])}>
			<a aria-label={mapLinkLabel} href={mapLinkUrl}>
				<Svg
					aria-hidden="true"
					className={styles['map-drawing__svg']}
					id="map-drawing"
					style={customProperties}
				/>
			</a>
		</div>
	);
};
