import { GraphQLClient } from 'graphql-request';

export const shopifyClient = new GraphQLClient(
	process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_URL ?? '',
	{
		headers: {
			'X-Shopify-Storefront-Access-Token':
				process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_ACCESS_TOKEN ?? '',
		},
	},
);
