import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';

import { gaCustomDimensions } from '@common/utils/tracking';

declare global {
	interface Window {
		SL: any;
		ttq: any;
		clarity: any;
	}
}

const BRANCH = process.env.NEXT_PUBLIC_BRANCH ?? '';
const GOOGLE_ANALYTICS_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID ?? '';
const GOOGLE_ANALYTICS_4_ID =
	process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_4_ID ?? '';
const QUANTCAST_ID = process.env.NEXT_PUBLIC_QUANTCAST_ID ?? '';
const SPARKLOOP_ID = process.env.NEXT_PUBLIC_SPARKLOOP_ID ?? '';
const TIKTOK_PIXEL_ID = process.env.NEXT_PUBLIC_TIKTOK_PIXEL_ID ?? '';
const MICROSOFT_CLARITY_ID = process.env.NEXT_PUBLIC_MICROSOFT_CLARITY_ID ?? '';
const GOOGLE_TAG_MANAGER_ID =
	process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID ?? '';

export const TrackingCode = () => {
	const router = useRouter();

	// Track subsequent page visits
	useEffect(() => {
		const handleRouteChange = (url: string) => {
			ga('send', 'pageview', url);
			window.ttq.page();
		};

		router.events.on('routeChangeComplete', handleRouteChange);

		return () => {
			router.events.off('routeChangeComplete', handleRouteChange);
		};
	}, [router.events]);

	// Track outbound links
	const outboundLinkHandler = (event: Event) => {
		const { href } = event.target as HTMLAnchorElement;

		ga('send', 'event', {
			eventAction: 'click',
			eventCategory: 'Outbound Link',
			eventLabel: href,
		});
	};

	useEffect(() => {
		const outboundLinks = document.querySelectorAll('a[href*="://"]');

		outboundLinks.forEach((outboundLink) => {
			outboundLink.addEventListener('click', outboundLinkHandler);
		});

		return () => {
			outboundLinks.forEach((outboundLink) => {
				outboundLink.removeEventListener('click', outboundLinkHandler);
			});
		};
	});

	// Track outbound forms
	const outboundFormHandler = (event: Event) => {
		const formElement = event.currentTarget as HTMLFormElement;

		const urlObject = new URL(formElement.action);
		const isMailchimpUrl = urlObject.host.endsWith('list-manage.com');

		const eventLabel = isMailchimpUrl
			? `${formElement.dataset.listId} / ${formElement.dataset.location}`
			: formElement.action;

		if (isMailchimpUrl && window.SL) {
			const formData = new FormData(formElement);
			const email = formData.get('EMAIL');

			window.SL.trackSubscriber(email);
		}

		ga('send', 'event', {
			eventAction: 'submit',
			eventCategory: 'Outbound Form',
			eventLabel,
		});
	};

	useEffect(() => {
		const outboundForms = document.querySelectorAll('form[action*="://"]');

		outboundForms.forEach((outboundForm) => {
			outboundForm.addEventListener('submit', outboundFormHandler);
		});

		return () => {
			outboundForms.forEach((outboundForm) => {
				outboundForm.removeEventListener('submit', outboundFormHandler);
			});
		};
	});

	return (
		<>
			<Script id="google-tag-manager-new" strategy="afterInteractive">
				{/* https://support.google.com/tagmanager/answer/6103696?hl=en */}
				{`
					(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
					new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
					j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
					'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
					})(window,document,'script','dataLayer','${GOOGLE_TAG_MANAGER_ID}');
				`}
			</Script>
			<noscript id="google-tag-manager-noscript">
				<iframe
					height="0"
					src={`https://www.googletagmanager.com/ns.html?id=${GOOGLE_TAG_MANAGER_ID}`}
					style={{ display: 'none', visibility: 'hidden' }}
					title="google-tag-manager-noscript"
					width={0}
				/>
			</noscript>
			<Script id="google-analytics" strategy="afterInteractive">
				{/* https://developers.google.com/analytics/devguides/collection/analyticsjs */}
				{`
					(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
					(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
					m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
					})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
					ga('create', '${GOOGLE_ANALYTICS_ID}', 'auto', {allowLinker: true});
					ga('set', '${gaCustomDimensions.BRANCH}', '${BRANCH}')
					ga('set', 'anonymizeIp', true);
					ga('set', 'transport', 'beacon');
					ga('require', 'linker');
					ga('linker:autoLink', ['dice.fm']);
					ga('send', 'pageview');
				`}
			</Script>
			{/* https://developers.google.com/analytics/devguides/collection/ga4 */}
			<Script
				id="google-tag-manager"
				src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_4_ID}`}
				strategy="afterInteractive"
			/>
			<Script id="google-analytics-4" strategy="afterInteractive">
				{`
  				window.dataLayer = window.dataLayer || [];
  				function gtag(){dataLayer.push(arguments);}
  				gtag('js', new Date());
		  		gtag('config', '${GOOGLE_ANALYTICS_4_ID}');
				`}
			</Script>
			<Script id="tiktok-analytics" strategy="afterInteractive">
				{/* https://ads.tiktok.com/marketing_api/docs?rid=yqyw52eh4of&id=1701890973258754 */}
				{`
					!function (w, d, t) {
						w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
						)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
						ttq.load('${TIKTOK_PIXEL_ID}');
						ttq.page();
						}(window, document, 'ttq');
				`}
			</Script>
			<Script id="quantcast-analytics" strategy="afterInteractive">
				{/* https://help.quantcast.com/hc/en-us/articles/360041751413-Implement-Quantcast-Tag-Directly-on-Your-Site */}
				{`
					window._qevents = window._qevents || [];
					(function() {
						var elem = document.createElement('script');
						elem.src = (document.location.protocol == 'https:' ? 'https://secure' : 'http://edge') + '.quantserve.com/quant.js';
						elem.async = true;
						elem.type = 'text/javascript';
						var scpt = document.getElementsByTagName('script')[0];
						scpt.parentNode.insertBefore(elem, scpt);
					})();
					window._qevents.push({
						qacct: '${QUANTCAST_ID}'
					});
				`}
			</Script>
			<Script id="microsoft-clarity" strategy="afterInteractive">
				{/* https://clarity.microsoft.com/projects/view/kqwcj9nphd/gettingstarted */}
				{`
					(function(c,l,a,r,i,t,y){
						c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
						t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
						y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
					})(window, document, "clarity", "script", "${MICROSOFT_CLARITY_ID}");
				`}
			</Script>
			<Script
				data-sparkloop
				id="sparkloop"
				src={`https://script.sparkloop.app/${SPARKLOOP_ID}.js`}
				strategy="afterInteractive"
			/>
		</>
	);
};
