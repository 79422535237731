import { useRouter } from 'next/dist/client/router';
import classNames from 'classnames';

import { NavigationAnchor } from '@components/navigation/NavigationAnchor';
import { NewsletterSection } from '@components/newsletterSection/NewsletterSection';
import { Svg } from '@components/svg/Svg';

import { getCopyright } from '@common/utils/site';
import { SiteFooter } from '@interfaces';

import styles from './footer.module.scss';
import { FooterLinks } from './FooterLinks';
import { FooterLocation } from './FooterLocation';

interface SiteFooterProps {
	footer: SiteFooter;
}

export const Footer = ({
	footer: {
		latestLinks,
		exploreLinks,
		communityLinks,
		aboutLinks,
		location,
		socialLinks,
		infoPages,
		newsletterSection,
	},
}: SiteFooterProps) => {
	const router = useRouter();
	const copyright = getCopyright();
	return (
		<footer>
			<div className={styles.footer}>
				<div
					className={classNames(
						styles.section,
						styles.primary,
						styles['internal-links'],
					)}
				>
					<FooterLinks className={styles.latest} {...latestLinks} />
					<FooterLinks className={styles.explore} {...exploreLinks} />
					<FooterLinks className={styles.community} {...communityLinks} />
					<FooterLinks className={styles.about} {...aboutLinks} />
				</div>
				<div
					className={classNames(
						styles.section,
						styles.primary,
						styles['external-links'],
					)}
				>
					<NewsletterSection
						className={styles.newsletter}
						location="website footer"
						{...newsletterSection}
					/>
					<FooterLocation className={styles.location} {...location} />
					<FooterLinks className={styles.social} external {...socialLinks} />
				</div>
				<div className={classNames(styles.section, styles.secondary)}>
					<div className={styles.copyright}>
						<Svg id="logo" />
						<p>{copyright}</p>
					</div>
					{infoPages.length ? (
						<ul className={classNames(styles['info-pages'], 'list-unstyled')}>
							{infoPages.map(({ id, title, slug }) => {
								const url = `/info/${slug}`;
								const isActive = router.asPath.includes(url);
								return (
									<li key={id}>
										<NavigationAnchor isActive={isActive} url={url}>
											{title}
										</NavigationAnchor>
									</li>
								);
							})}
						</ul>
					) : null}
				</div>
			</div>
		</footer>
	);
};
