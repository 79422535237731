import { ShopifyGetCollectionListResponse } from 'src/interfaces/shopify';

import { shopifyClient } from '@data/shopify/shopifyClient';

interface GetCollectionListOptions {
	collectionCount?: number;
}

export const getCollectionList = async (options?: GetCollectionListOptions) => {
	const { collectionCount = 10 } = options || {};

	return shopifyClient.request<ShopifyGetCollectionListResponse>(
		`
			query getCollectionList($collectionCount: Int!) {
				collections(first: $collectionCount) {
					edges {
						cursor
						node {
							id
							title
							handle
						}
					}
				}
			}
		`,
		{
			collectionCount,
		},
	);
};
