import classnames from 'classnames';

import { markdownToHtml } from '@common/utils/markdown';

import styles from './markdown.module.scss';

type HeadingLevel = 2 | 3 | 4 | 5 | 6;

interface MarkdownProps {
	className?: string;
	headingLevel?: HeadingLevel;
	text: string;
	isZinePostBody?: boolean;
}

export const Markdown = ({
	className,
	headingLevel = 2,
	text,
	isZinePostBody = false,
}: MarkdownProps) => {
	return (
		<div
			className={classnames(className, styles.markdown)}
			dangerouslySetInnerHTML={{
				__html: markdownToHtml(text, headingLevel, isZinePostBody),
			}}
		/>
	);
};
