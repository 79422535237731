import { useEffect, useRef } from 'react';

export const useSetInterval = <T extends (...args: never[]) => unknown>(
	callback: T,
	delay: number,
): void => {
	const intervalCallback = useRef<T>(callback);

	useEffect(() => {
		intervalCallback.current = callback;
	}, [callback]);

	useEffect(() => {
		const id = setInterval(() => intervalCallback.current(), delay);

		return () => clearInterval(id);
	}, [delay]);
};
