import Link from 'next/link';
import { useRouter } from 'next/router';
import classNames from 'classnames';

import { Cart } from '@components/cart/Cart';
import { Svg } from '@components/svg/Svg';

import { useCart } from '@context/CartContext';
import { useUser } from '@context/UserContext';
import { ACCOUNT_URL, SIGN_IN_URL } from '@data/constants';

import styles from './navigation-add-on.module.scss';

type NavigationAddOnProps = {
	hideLabelOnDesktop?: boolean;
	overrideDesktopColor?: 'sand' | 'black';
	showCart?: boolean;
};

export const NavigationAddOn = ({
	hideLabelOnDesktop = false,
	showCart = true,
	overrideDesktopColor,
}: NavigationAddOnProps) => {
	const router = useRouter();
	const { authUser, subscription } = useUser();
	const { cart } = useCart();

	const isCartVisible = showCart && cart && cart.totalQuantity > 0;
	const tierLevel = subscription?.prices?.products?.metadata?.tier_level;

	return (
		<div
			className={classNames(styles['navigation-addon'], {
				[styles['navigation-addon--hide-label-on-desktop']]: hideLabelOnDesktop,
				[styles[`navigation-addon--tier-level-${tierLevel}`]]: tierLevel,
				[styles[
					`navigation-addon--override-desktop-color-${overrideDesktopColor}`
				]]: overrideDesktopColor,
			})}
		>
			{isCartVisible ? <Cart /> : null}
			<Link
				aria-label="My account"
				className={classNames(styles['link-wrapper'], 'navigation-anchor', {
					'navigation-anchor--is-active':
						(!authUser && router.asPath.includes(SIGN_IN_URL)) ||
						(authUser && router.asPath.includes(ACCOUNT_URL)),
					[styles['link-wrapper--cart-visible']]: isCartVisible,
				})}
				href={authUser ? ACCOUNT_URL : SIGN_IN_URL}
			>
				{!authUser ? <p className={styles.login}>Sign in</p> : null}
				{authUser ? <p className={styles['my-account']}>My account</p> : null}
				<Svg
					className={classNames(styles['user-icon'], {
						[styles['user-icon--cart-visible']]: isCartVisible,
					})}
					id="account-icon"
				/>
			</Link>
		</div>
	);
};
