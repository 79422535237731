import { useState } from 'react';
import classNames from 'classnames';

import { ClippedImage } from '@components/clippedImage/ClippedImage';
import { Markdown } from '@components/markdown/Markdown';
import { NewsletterApiForm } from '@components/newsletterApiForm/NewsletterApiForm';

import { handleError } from '@common/utils/handleError';
import { getMailchimpUrl } from '@common/utils/mailchimp';
import {
	ApiSubscribeErrorResponse,
	ApiSubscribeSuccessResponse,
	NewsletterSectionRecord,
	NewsletterSignUpLocation,
} from '@interfaces';

import styles from './newsletter-section.module.scss';

interface NewsletterSectionProps
	extends Omit<NewsletterSectionRecord, '__typename'> {
	isHeadingVisible?: boolean;
	className?: string;
	location: NewsletterSignUpLocation;
	isCompact?: boolean;
}

export const NewsletterSection = ({
	className,
	form,
	isHeadingVisible = true,
	text,
	title,
	location,
	backgroundColor,
	textColor,
	clippedImage,
	isCompact = true,
	isAlternate,
}: NewsletterSectionProps) => {
	const {
		mailchimpListId,
		mailchimpFormLocationFieldTag,
		thankYouMessage,
		mailchimpTags,
	} = form;
	const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	const style = {
		'--background-color': backgroundColor?.hex,
		'--text-color': textColor?.hex,
	} as React.CSSProperties;

	const onSuccess = ({ status, email }: ApiSubscribeSuccessResponse) => {
		if (status === 'subscribed') {
			setErrorMessage(null);
			setIsSuccessfullySubmitted(true);
		} else {
			setIsSuccessfullySubmitted(false);
			setErrorMessage(
				`We can’t subscribe you, please try our [Mailchimp form](${getMailchimpUrl(
					{
						mailchimpListId,
						email,
					},
				)})`,
			);
		}
	};

	const onError = ({ error }: ApiSubscribeErrorResponse) => {
		handleError(error);
		setErrorMessage('We’re sorry, something went wrong...');
	};

	return (
		<div
			className={classNames(className, styles['newsletter-section'], {
				[styles['is-compact']]: isCompact,
				[styles['is-alternate']]: isAlternate && !isCompact,
				[styles['center-align']]: !clippedImage && !isCompact,
			})}
			style={style}
		>
			<div className={styles.content}>
				{isSuccessfullySubmitted ? (
					<>
						<h2 className={styles.title}>{thankYouMessage.title}</h2>
						<Markdown
							className={styles.text}
							text={isSuccessfullySubmitted ? thankYouMessage.text : text}
						/>
					</>
				) : (
					<>
						{isHeadingVisible ? (
							<h2 className={styles.title}>{title}</h2>
						) : null}
						<Markdown className={styles.text} text={text} />
						<NewsletterApiForm
							className={styles.form}
							listId={mailchimpListId}
							location={location}
							locationFieldTag={mailchimpFormLocationFieldTag}
							mailchimpTags={mailchimpTags}
							onError={onError}
							onSuccess={onSuccess}
						/>
						{errorMessage ? <Markdown text={errorMessage} /> : null}
					</>
				)}
			</div>
			{clippedImage && !isCompact ? (
				<ClippedImage
					className={styles.image}
					{...clippedImage}
					sizes="(min-width: 56.25em) 52vw, 100vw"
				/>
			) : null}
		</div>
	);
};
