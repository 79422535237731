import { marked } from 'marked';
import TurndownService from 'turndown';

import { HeadingLevel } from '@interfaces';

marked.setOptions({
	breaks: true,
});

const linkRenderer = (
	href: string,
	title: string | null | undefined,
	text: string,
	markExternal = true,
) => {
	const titleAttribute = title ? `title="${title}"` : '';

	const isExternal = href.includes('://');
	const className =
		markExternal && isExternal ? 'anchor anchor--external' : 'anchor';

	return `<a class="${className}" href="${href}" ${titleAttribute}>${text}</a>`;
};

const getMarkedRenderer = (headingLevel: HeadingLevel) => {
	return {
		// update headings to the desired level
		heading(text: string) {
			return `<h${headingLevel}>${text}</h${headingLevel}>`;
		},
		link: linkRenderer,
		list(body: string, ordered: boolean) {
			const tag = ordered ? 'ol' : 'ul';

			return `<${tag} class="list">${body}</${tag}>`;
		},
		listitem(text: string) {
			// we need to wrap the text element in a div to make sure our list layout works see list.scss
			return `<li><div>${text}</div></li>`;
		},
	};
};

const getZineMarkedRenderer = () => {
	return {
		heading(text: string, level: number) {
			const tag = level > 2 ? 'h3' : `h${level + 1}`;
			const className = level === 1 ? 'font-t3-title' : 'font-t6-medium';
			return `<${tag} class=${className}>${text}</${tag}>`;
		},
		link: (href: string, title: string | null | undefined, text: string) => {
			return linkRenderer(href, title, text, false);
		},
	};
};

export const markdownToHtml = (
	markdown: string,
	headingLevel: HeadingLevel,
	isZinePostBody: boolean,
) => {
	marked.use({
		renderer: getMarkedRenderer(headingLevel),
	});

	if (isZinePostBody) {
		marked.use({
			renderer: getZineMarkedRenderer(),
		});
	}

	return marked(markdown);
};

const turndownService = new TurndownService({
	bulletListMarker: '-',
	emDelimiter: '*',
	headingStyle: 'atx',
	linkStyle: 'inlined',
	strongDelimiter: '**',
});

export const htmlToMarkdown = (html: string) => turndownService.turndown(html);
