import { useRef } from 'react';
import classNames from 'classnames';

import { useMarquee } from '@hooks/useMarquee';

import styles from './text-marquee.module.scss';

interface TextMarqueeProps {
	className?: string;
	text: string;
	isElsewherePresentsVenue?: boolean;
}

export const TextMarquee = ({
	className,
	text,
	isElsewherePresentsVenue = false,
}: TextMarqueeProps) => {
	const marqueeElementRef = useRef<HTMLDivElement>(null);
	const { isAnimated } = useMarquee(marqueeElementRef);

	const style = {
		'--marquee-text-character-count': text.length,
	} as React.CSSProperties;

	return (
		<div
			className={classNames(className, styles.marquee, {
				[styles['is-animated']]: isAnimated,
				[styles['is-venue']]: isElsewherePresentsVenue,
			})}
			ref={marqueeElementRef}
			style={isAnimated ? style : undefined}
		>
			<div className={styles.text}>{text}</div>
		</div>
	);
};
