import classNames from 'classnames';

import { ShopifyCart } from '@interfaces';

import styles from './cart-content.module.scss';
import { CartItem } from './CartItem';

interface CartContentProps {
	className?: string;
	cart: ShopifyCart;
}

export const CartContent = ({ className, cart }: CartContentProps) => {
	const {
		lines: { edges: cartLineEdges },
	} = cart;

	return (
		<ul className={classNames(styles.content, className, 'list-unstyled')}>
			{cartLineEdges.map((cartLineEdge) => {
				const {
					node: { id, quantity },
				} = cartLineEdge;

				const items = [];

				for (let index = 0; index < quantity; index += 1) {
					items.push(
						<li
							className={classNames(styles['list-item'], {
								[styles['is-first-list-item']]: index === 0,
							})}
							key={`${id}-${index}`}
						>
							<CartItem cart={cart} cartLineEdge={cartLineEdge} />
						</li>,
					);
				}

				return items;
			})}
		</ul>
	);
};
