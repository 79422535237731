import classNames from 'classnames';

import { neverError } from '@common/utils/neverError';

import styles from './membership-illustration.module.scss';

const srcSetWidths = [400, 800, 1200, 1600];

const tierIllustrationDimensions = new Map([
	['1', { width: 1600, height: 1170 }],
	['2', { width: 1600, height: 1423 }],
	['3', { width: 1600, height: 1742 }],
]);

const upsellIllustrationDimensions = { width: 1600, height: 1100 };

const getTierLevelSrc = (
	tierLevel: string,
	width: number,
	type: 'webp' | 'png',
) => {
	return `/illustrations/tier-level-${tierLevel}-${width}w.${type}`;
};

const getTierLevelSrcSet = (tierLevel: string, type: 'webp' | 'png') => {
	return srcSetWidths
		.map((width) => `${getTierLevelSrc(tierLevel, width, type)} ${width}w`)
		.join(', ');
};

const getUpsellSrc = (width: number, type: 'webp' | 'png') => {
	return `/illustrations/membership-upsell-${width}w.${type}`;
};

const getUpsellSrcSet = (type: 'webp' | 'png') => {
	return srcSetWidths
		.map((width) => `${getUpsellSrc(width, type)} ${width}w`)
		.join(', ');
};

const getImageData = (type: 'upsell' | 'tier', tierLevel?: string) => {
	switch (type) {
		case 'upsell':
			return {
				dimensions: upsellIllustrationDimensions,
				srcSetPng: getUpsellSrcSet('png'),
				srcSetWebp: getUpsellSrcSet('webp'),
				src: getUpsellSrc(400, 'png'),
			};
		case 'tier':
			if (!tierLevel) {
				return null;
			}
			return {
				dimensions: tierIllustrationDimensions.get(tierLevel),
				srcSetPng: getTierLevelSrcSet(tierLevel, 'png'),
				srcSetWebp: getTierLevelSrcSet(tierLevel, 'webp'),
				src: getTierLevelSrc(tierLevel, 400, 'png'),
			};
		default: {
			throw neverError('Unknown illustration type', module);
		}
	}
};

interface MembershipIllustrationProps {
	className?: string;
	sizes: string;
	tierLevel?: string;
	type?: 'upsell' | 'tier';
}

export const MembershipIllustration = ({
	className,
	sizes,
	tierLevel,
	type = 'tier',
}: MembershipIllustrationProps) => {
	const imageData = getImageData(type, tierLevel);

	if (!imageData) {
		return null;
	}

	const { dimensions, srcSetPng, srcSetWebp, src } = imageData;

	if (!dimensions) {
		return null;
	}

	const customProperties = {
		'--illustration-aspect-ratio': `calc(${dimensions.width} / ${dimensions.height})`,
	} as React.CSSProperties;

	return (
		<picture
			className={classNames(className, styles.illustration)}
			style={customProperties}
		>
			<source srcSet={srcSetWebp} type="image/webp" />
			<source srcSet={srcSetPng} type="image/png" />
			<img
				alt=""
				className={styles.image}
				height={dimensions.height}
				role="presentation"
				sizes={sizes}
				src={src}
				width={dimensions.width}
			/>
		</picture>
	);
};
