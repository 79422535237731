import classNames from 'classnames';

import { Svg } from '@components/svg/Svg';

import styles from './navigation-fullscreen-open-button-content.module.scss';

type NavigationFullscreenOpenButtonContentProps = {
	hideBubble?: boolean;
	overrideMobileOpenButtonColor?: 'sand' | null;
};

export const NavigationFullscreenOpenButtonContent = ({
	hideBubble = false,
	overrideMobileOpenButtonColor,
}: NavigationFullscreenOpenButtonContentProps) => {
	return (
		<div
			className={classNames(styles.content, {
				[styles['hide-bubble']]: hideBubble,
				[styles[
					`override-mobile-open-button-color-${overrideMobileOpenButtonColor}`
				]]: overrideMobileOpenButtonColor,
			})}
		>
			<Svg id="hamburger" />
		</div>
	);
};
