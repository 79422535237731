import { useEffect, useRef } from 'react';
import classNames from 'classnames';

import { Svg } from '@components/svg/Svg';
import { Video } from '@components/video/Video';

import { splitHeading } from '@common/utils/splitHeading';
import { VideoBannerRecord } from '@interfaces';

import styles from './video-banner.module.scss';

interface VideoBannerProps {
	className?: string;
	videoBanner: VideoBannerRecord;
}

export const VideoBanner = ({ className, videoBanner }: VideoBannerProps) => {
	const { title, media, captions } = videoBanner;
	const videoBannerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const videoBannerElement = videoBannerRef.current;

		if (videoBannerElement) {
			videoBannerElement.style.setProperty(
				'--video-banner-height',
				`${window.innerHeight}px`,
			);
		}
	}, []);

	return (
		<div
			aria-label="Video banner"
			className={classNames(className, styles['video-banner'])}
			ref={videoBannerRef}
			role="region"
		>
			<h2 className={styles['video-banner__heading']}>{splitHeading(title)}</h2>
			<div className={styles['video-banner__logo-wrapper']}>
				<Svg
					aria-hidden
					className={styles['video-banner__logo']}
					id="video-banner-logo"
				/>
			</div>
			<Video
				captions={captions}
				className={styles['video-banner__video']}
				hasAutoPlay
				hasControls={false}
				isLoop
				isMuted
				media={media}
			/>
		</div>
	);
};
