import { ShopifyGetProductResponse } from 'src/interfaces/shopify';

import { SHOP_METAFIELD_NAMESPACE } from '@data/constants';
import {
	externalVideoFragment,
	fileMetafieldFragment,
	imageFragment,
	mediaFragment,
	mediaImageFragment,
	moneyFragment,
	productOptionFragment,
	productPriceRangeFragment,
	productVariantFragment,
	seoFragment,
	textMetafieldFragment,
	videoFragment,
} from '@data/shopify/fragments';
import { shopifyClient } from '@data/shopify/shopifyClient';

interface GetProductOptions {
	variantCount?: number;
	mediaCount?: number;
}

export const getProduct = async (
	handle: string,
	options?: GetProductOptions,
) => {
	const { variantCount = 30, mediaCount = 10 } = options || {};

	return shopifyClient.request<ShopifyGetProductResponse>(
		`
			query getProduct($handle: String!, $mediaCount: Int!, $variantCount: Int!, $metafieldNamespace: String!) {
				product(handle: $handle) {
					id
					title
					handle
					description
					descriptionHtml
					seo {
						...Seo
					}
					tags
					availableForSale
					priceRange {
						...ProductPriceRange
					}
					options {
						...ProductOption
					}
					variants(first: $variantCount) {
						edges {
							node {
								...ProductVariant
							}
						}
					}
					media(first: $mediaCount) {
						edges {
							node {
								...Media
							}
						}
					}
					supportingMedia1: metafield(namespace: $metafieldNamespace, key: "supporting_media_1") {
						...FileMetafield
					}
					supportingMedia2: metafield(namespace: $metafieldNamespace, key: "supporting_media_2") {
						...FileMetafield
					}
					supportingMedia3: metafield(namespace: $metafieldNamespace, key: "supporting_media_3") {
						...FileMetafield
					}
					supportingMedia4: metafield(namespace: $metafieldNamespace, key: "supporting_media_4") {
						...FileMetafield
					}
					faq: metafield(namespace: $metafieldNamespace, key: "faq") {
						...TextMetafield
					}
					sizeAndFit: metafield(namespace: $metafieldNamespace, key: "size_and_fit") {
						...TextMetafield
					}
					details: metafield(namespace: $metafieldNamespace, key: "details") {
						...TextMetafield
					}
				}
			}
			${externalVideoFragment}
			${fileMetafieldFragment}
			${imageFragment}
			${mediaFragment}
			${mediaImageFragment}
			${moneyFragment}
			${productOptionFragment}
			${productPriceRangeFragment}
			${productVariantFragment}
			${seoFragment}
			${textMetafieldFragment}
			${videoFragment}
		`,
		{
			handle,
			variantCount,
			mediaCount,
			metafieldNamespace: SHOP_METAFIELD_NAMESPACE,
		},
	);
};
