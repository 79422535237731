import { createContext, useContext, useState } from 'react';
import { stringify } from 'qs';

import { URL_RESERVATION } from '@common/utils/programme';
import { ReservationWidget as ReservationWidgetDato } from '@interfaces';

import { ReservationWidget } from './ReservationWidget';

interface ReservationWidgetContext {
	src: string;
	isVisible: boolean;
	displayWidget: (
		params: Partial<WidgetParams>,
		options: WidgetOptions,
	) => void;
	hideWidget: () => void;
	options: WidgetOptions;
}

const ReservationWidgetContext = createContext<
	Partial<ReservationWidgetContext>
>({
	src: '',
	isVisible: false,
});

interface WidgetParams {
	defaultDate: string;
	defaultTime: string;
}

export type WidgetType = 'table' | 'party-package';

export interface WidgetOptions {
	url: string;
	type: WidgetType;
	title?: string;
	description?: string;
}

const initialParams = {
	defaultDate: '',
	defaultTime: '',
};

interface ReservationWidgetProviderProps {
	children?: React.ReactNode;
	content: ReservationWidgetDato;
}

const getContent = (
	type: WidgetType,
	{
		titleTable,
		descriptionTable,
		titlePartyPackage,
		descriptionPartyPackage,
	}: ReservationWidgetDato,
) => ({
	title: type === 'table' ? titleTable : titlePartyPackage,
	description: type === 'table' ? descriptionTable : descriptionPartyPackage,
});

export const ReservationWidgetProvider = ({
	children,
	content,
}: ReservationWidgetProviderProps) => {
	const [params, setParams] = useState(initialParams);
	const [options, setOptions] = useState<WidgetOptions>({
		url: URL_RESERVATION,
		type: 'table',
	});
	const [isVisible, setIsVisible] = useState(false);
	const queryParams = stringify(params);
	const src = `${options.url}${
		options.url.includes('?') ? '&' : '?'
	}${queryParams}`;
	const displayWidget = (
		nextParams: Partial<WidgetParams> = {},
		nextOptions: WidgetOptions,
	) => {
		setParams({
			...params,
			...nextParams,
		});
		setOptions({ ...nextOptions, ...getContent(nextOptions.type, content) });
		setIsVisible(true);
	};

	const hideWidget = () => {
		setParams(initialParams);
		setIsVisible(false);
	};
	return (
		<ReservationWidgetContext.Provider
			value={{ src, displayWidget, hideWidget, isVisible, options }}
		>
			{children}
			<ReservationWidget />
		</ReservationWidgetContext.Provider>
	);
};

export const useReservationWidget = (): ReservationWidgetContext => {
	const { isVisible, src, displayWidget, hideWidget, options } = useContext(
		ReservationWidgetContext,
	);
	if (
		!displayWidget ||
		!hideWidget ||
		typeof isVisible === 'undefined' ||
		!src ||
		!options
	) {
		throw new Error(
			'You can only use this hook within the reservation widget context',
		);
	}
	return { isVisible, src, displayWidget, hideWidget, options };
};
