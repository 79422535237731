import { ParsedUrlQuery } from 'querystring';

import { getDatoPage } from '@common/utils/dato';

export const getBodyCustomProperties = (
	pageProps: any,
): React.CSSProperties | undefined => {
	// Get the background either from a `pageProps` or from `datoPage`
	const datoPage = getDatoPage(pageProps);
	const backgroundColor =
		pageProps?.backgroundColor || datoPage?.backgroundColor || undefined;

	if (backgroundColor) {
		const { hex, red, green, blue } = backgroundColor;

		return {
			'--body-background-color-hex': hex,
			'--body-background-color-rgb': `${red},${green},${blue}`,
		} as React.CSSProperties;
	}

	return undefined;
};

export const getBodyId = (path: string, query: ParsedUrlQuery) => {
	// Set a body id for styling purposes
	if (path === '/') {
		return 'body-home';
	}

	const regex = new RegExp(/\[.*\]/);
	const pathParts = path.split('/').filter((pathPart) => pathPart);

	if (pathParts.length && !regex.test(pathParts[0]) && !query.slug) {
		return `body-${pathParts[0]}`;
	}

	return undefined;
};
