import { Svg } from '@components/svg/Svg';

import { getMailchimpUrl } from '@common/utils/mailchimp';
import { isGtagAvailable } from '@common/utils/tracking';

import styles from './unsubscribed-warning-banner-content.module.scss';

interface UnsubscribedWarningBannerContentProps {
	email: string;
}

export const UnsubscribedWarningBannerContent = ({
	email,
}: UnsubscribedWarningBannerContentProps) => {
	const newsletterFormListId = process.env.NEXT_PUBLIC_MAILCHIMP_LIST_ID || '';
	const resubscribeUrl = getMailchimpUrl({
		mailchimpListId: newsletterFormListId,
		email,
	});

	const onClick = () => {
		if (isGtagAvailable()) {
			gtag('event', 'click', {
				event_category: 'Unsubscribed Warning Banner',
				event_label: 'Unsubscribed Warning Banner Resubscribe Click',
			});
		}
	};

	return (
		<div className={styles.content}>
			<h2 className={styles.heading}>
				<Svg
					aria-hidden="true"
					className={styles['warning-symbol']}
					id="warning-symbol"
				/>
				<span>We are unable to email you.</span>
			</h2>
			<p className={styles.copy}>
				Your email <span className={styles.email}>({email})</span> was
				unsubscribed from our list at some point in the past.{' '}
				<a className="anchor" href={resubscribeUrl} onClick={onClick}>
					You can re-subscribe here.
				</a>
			</p>
		</div>
	);
};
