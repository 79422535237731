import { useRouter } from 'next/dist/client/router';
import classNames from 'classnames';

import { NavigationAnchor } from '@components/navigation/NavigationAnchor';

import { getNavigationAnchor } from '@common/utils/navigation';
import { NavigationLinkGroup } from '@interfaces';

import styles from './footer-links.module.scss';

interface FooterLinksProps extends NavigationLinkGroup {
	className?: string;
	external?: boolean;
}

export const FooterLinks = ({
	title,
	links,
	className,
	external = false,
}: FooterLinksProps) => {
	const router = useRouter();
	return (
		<div className={classNames(className, styles.social)}>
			<h2 className={styles.heading}>{title}</h2>
			<ul className={classNames(styles.list, 'list-unstyled')}>
				{links?.map((link) => {
					const anchor = getNavigationAnchor(router, link);

					if (!anchor) {
						return null;
					}

					if (external) {
						return (
							<li key={link.id}>
								<NavigationAnchor
									className={classNames(styles.link, 'anchor', {
										'anchor--external-arrow-style-diagonal': external,
									})}
									url={anchor.url}
								>
									{anchor.title}
								</NavigationAnchor>
							</li>
						);
					}

					return (
						<li key={link.id}>
							<NavigationAnchor {...anchor}>{anchor.title}</NavigationAnchor>
						</li>
					);
				})}
			</ul>
		</div>
	);
};
