import { useRouter } from 'next/router';
import classNames from 'classnames';

import { getCopyright } from '@common/utils/site';
import { LocalPage } from '@interfaces';

import styles from './navigation-fullscreen-footer.module.scss';
import { NavigationAnchor } from './NavigationAnchor';

interface NavigationFullscreenFooterProps {
	infoPages: LocalPage[];
	className?: string;
}

export const NavigationFullscreenFooter = ({
	infoPages,
	className,
}: NavigationFullscreenFooterProps) => {
	const copyright = getCopyright();
	const router = useRouter();
	return (
		<div
			className={classNames(className, styles['navigation-fullscreen-footer'])}
		>
			<ul
				className={classNames(
					styles['navigation-fullscreen-footer__info-pages'],
					'list-unstyled',
				)}
			>
				{infoPages.length &&
					infoPages.map(({ id, title, slug }) => {
						const url = `/info/${slug}`;
						const isActive = router.asPath.includes(url);
						return (
							<li key={id}>
								<NavigationAnchor isActive={isActive} url={url}>
									{title}
								</NavigationAnchor>
							</li>
						);
					})}
				<li className={styles['navigation-fullscreen-footer__copyright']}>
					{copyright}
				</li>
			</ul>
		</div>
	);
};
