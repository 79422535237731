import { ShopifyGetCartResponse } from 'src/interfaces/shopify';

import { CART_LINE_COUNT } from '@data/constants';
import { shopifyClient } from '@data/shopify/shopifyClient';

import {
	cartFragment,
	imageFragment,
	moneyFragment,
	productOptionFragment,
} from '../fragments';

export const getCart = async (cartId: string) => {
	return shopifyClient.request<ShopifyGetCartResponse>(
		`
			query getCart($cartId: ID!, $lineCount: Int!) {
				cart(id: $cartId) {
					...Cart
				}
			}
			${cartFragment}
			${imageFragment}
			${moneyFragment}
			${productOptionFragment}
		`,
		{
			cartId,
			lineCount: CART_LINE_COUNT,
		},
	);
};
