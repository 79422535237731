import {
	cartFragment,
	imageFragment,
	moneyFragment,
	productOptionFragment,
} from '@data/shopify/fragments';
import { shopifyClient } from '@data/shopify/shopifyClient';
import { ShopifyCartCreateResponse, ShopifyCartLineInput } from '@interfaces';

export const cartCreate = async (lines?: ShopifyCartLineInput[]) => {
	return shopifyClient.request<ShopifyCartCreateResponse>(
		`
			mutation cartCreate($lines: [CartLineInput!], $lineCount: Int!) {
				cartCreate(input: {lines: $lines}) {
					cart {
						...Cart
					}
				}
			}
			${cartFragment}
			${imageFragment}
			${moneyFragment}
			${productOptionFragment}
		`,
		{
			lines,
			lineCount: 30,
		},
	);
};
