import { handleError } from './handleError';

export const isGoogleAnalyticsAvailable = () => typeof ga === 'function';
export const isGtagAvailable = () => typeof gtag === 'function';
export const isFbqAvailable = () => typeof fbq === 'function';

export const gaCustomDimensions = {
	BRANCH: 'dimension1',
};

export enum UTM {
	Source = 'utm_source',
	Medium = 'utm_medium',
	Campaign = 'utm_campaign',
	Term = 'utm_term',
}

export enum DiceLinkoutOriginPageTypes {
	SmartLander = 'smart_lander',
	EventPage = 'event_page',
}

interface ApplyUTMParams {
	url: string;
	sessionStorage: Storage;
	urlSearchParams: URLSearchParams;
	originPageType?: DiceLinkoutOriginPageTypes;
	pagePath?: string;
}

export const applyUTMParams = ({
	url,
	sessionStorage,
	urlSearchParams,
	originPageType,
	pagePath,
}: ApplyUTMParams) => {
	try {
		let utmSource = null;
		let utmMedium = null;
		let utmTerm = null;
		const utmCampaign = originPageType;

		if (!sessionStorage.getItem(UTM.Term) && pagePath) {
			sessionStorage.setItem(UTM.Term, pagePath);
		} else {
			utmTerm = sessionStorage.getItem(UTM.Term);
		}

		if (urlSearchParams.has(UTM.Source) || urlSearchParams.has(UTM.Medium)) {
			utmSource = urlSearchParams.get(UTM.Source);
			utmMedium = urlSearchParams.get(UTM.Medium);
		} else {
			utmSource = sessionStorage.getItem(UTM.Source);
			utmMedium = sessionStorage.getItem(UTM.Medium);
		}

		if (utmSource || utmMedium || utmCampaign || utmTerm) {
			const urlObj = new URL(url);
			const params = new URLSearchParams(urlObj.search);
			if (utmSource) {
				params.set(UTM.Source, utmSource);
			}
			if (utmMedium) {
				params.set(UTM.Medium, utmMedium);
			}
			if (utmCampaign) {
				params.set(UTM.Campaign, utmCampaign);
			}
			if (utmTerm) {
				params.set(UTM.Term, utmTerm);
			}
			urlObj.search = params.toString();
			return urlObj.toString();
		}

		return url;
	} catch (error) {
		handleError(error);
	}
};
