import { FormEvent, useState } from 'react';
import classNames from 'classnames';

import { Svg } from '@components/svg/Svg';

import { getErrorMessage } from '@common/utils/errorMessage';
import { isGtagAvailable } from '@common/utils/tracking';
import {
	ApiSubscribeErrorResponse,
	ApiSubscribeSuccessResponse,
	NewsletterSignUpLocation,
} from '@interfaces';

interface NewsletterApiFormProps {
	className?: string;
	emailInputLabel?: string;
	listId: string;
	location?: NewsletterSignUpLocation;
	locationFieldTag?: string;
	onError: (response: ApiSubscribeErrorResponse) => void;
	onSuccess: (response: ApiSubscribeSuccessResponse) => void;
	submitButtonLabel?: string;
	mailchimpTags?: string;
}

export const NewsletterApiForm = ({
	className,
	emailInputLabel = 'Enter your email',
	listId,
	location,
	locationFieldTag,
	onError,
	onSuccess,
	submitButtonLabel = 'Submit',
	mailchimpTags,
}: NewsletterApiFormProps) => {
	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
		try {
			event.preventDefault();

			setIsSubmitting(true);

			const formElement = event.currentTarget;
			const formData = new FormData(formElement);

			// We can't pass the `FormData` instance directly to `fetch` as that will cause it to automatically format
			// the request body as "multipart" and set the `Content-Type` request header to `multipart/form-data`.
			const plainFormData = Object.fromEntries(formData.entries());
			const formDataAsJsonString = JSON.stringify(plainFormData);

			const subscribeResponse = await fetch('/api/subscribe', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
				},
				body: formDataAsJsonString,
			})
				.then((response) => response.json())
				.then(
					(data) =>
						data as ApiSubscribeErrorResponse | ApiSubscribeSuccessResponse,
				);

			if ('error' in subscribeResponse) {
				onError(subscribeResponse);
			}

			if ('status' in subscribeResponse) {
				if (subscribeResponse.status === 'subscribed') {
					const email = formData.get('email');

					if (window.SL && email) {
						window.SL.trackSubscriber(email);
					}

					if (window.fbq) {
						window.fbq('track', 'Lead');
						window.fbq('track', 'CompleteRegistration');
					}

					if (isGtagAvailable()) {
						gtag('event', 'submit', {
							event_category: 'Newsletter API Form',
							event_label: `${listId} / ${location}`,
						});
					}

					formElement.reset();
				}

				onSuccess(subscribeResponse);
			}

			setIsSubmitting(false);
		} catch (error) {
			onError({
				error: getErrorMessage(error),
			});
		}
	};

	return (
		<form
			className={classNames(className, 'newsletter-form')}
			method="post"
			onSubmit={handleSubmit}
		>
			{location && locationFieldTag ? (
				<>
					<input name="location" readOnly type="hidden" value={location} />
					<input
						name="locationFieldTag"
						readOnly
						type="hidden"
						value={locationFieldTag}
					/>
				</>
			) : null}
			{mailchimpTags ? (
				<input name="tags" readOnly type="hidden" value={mailchimpTags} />
			) : null}
			<input name="listId" type="hidden" value={listId} />
			<input
				aria-label={emailInputLabel}
				autoComplete="email"
				className="newsletter-form__input"
				disabled={isSubmitting}
				name="email"
				placeholder={emailInputLabel}
				required
				type="email"
			/>
			<button
				aria-label={submitButtonLabel}
				className="newsletter-form__submit"
				disabled={isSubmitting}
				type="submit"
			>
				<Svg className="newsletter-form__arrow" id="arrow" />
			</button>
		</form>
	);
};
