import classNames from 'classnames';

import { Markdown } from '@components/markdown/Markdown';

import { LocationRecord } from '@interfaces';

import styles from './footer-location.module.scss';

interface FooterLocationProps extends LocationRecord {
	className?: string;
}

export const FooterLocation = ({
	title,
	address,
	mapLinkUrl,
	mapLinkLabel,
	className,
}: FooterLocationProps) => {
	return (
		<div className={classNames(className, styles.location)}>
			<h2 className={styles.heading}>{title}</h2>
			<Markdown text={address} />
			<a
				className={classNames(
					styles.link,
					'anchor',
					'anchor--external-arrow-style-diagonal',
				)}
				href={mapLinkUrl}
			>
				{mapLinkLabel}
			</a>
		</div>
	);
};
