import { Dialog } from '@components/dialog/Dialog';

import { useCart } from '@context/CartContext';

import styles from './cart.module.scss';
import { CartContent } from './CartContent';
import { CartFooter } from './CartFooter';
import { CartOpenButtonContent } from './CartOpenButtonContent';

export const Cart = () => {
	const { cart } = useCart();

	if (!cart) return null;

	return (
		<Dialog
			classNames={{
				overlay: styles.overlay,
				closeButton: styles.closeButton,
				container: styles.container,
				dialog: styles.dialog,
				openButton: styles.openButton,
				title: styles.title,
			}}
			closeButtonLabel="Close cart"
			id="cart-dialog"
			openButtonContent={
				<CartOpenButtonContent quantity={cart.totalQuantity} />
			}
			openButtonLabel="Open cart"
			title="Cart"
		>
			<CartContent cart={cart} className={styles.content} />
			<CartFooter cart={cart} className={styles.footer} />
		</Dialog>
	);
};
