import { useEffect } from 'react';

// Lazyloads video and iframe elements
export const useLazyloadMedia = () => {
	const layzloadInterSectionObserverCallback = (
		entries: IntersectionObserverEntry[],
	) => {
		entries.forEach(({ target, isIntersecting }) => {
			const src = target.getAttribute('src');
			const dataSrc = target.getAttribute('data-src');
			const { tagName } = target;

			if (isIntersecting && dataSrc) {
				target.removeAttribute('data-src');
				target.setAttribute('src', dataSrc);
			}

			if (!isIntersecting && src && tagName.toLowerCase() === 'video') {
				target.removeAttribute('src');
				target.setAttribute('data-src', src);
			}
		});
	};

	useEffect(() => {
		const isIntersectionObserverSupported = 'IntersectionObserver' in window;

		if (isIntersectionObserverSupported) {
			const intersectionObserver = new IntersectionObserver(
				layzloadInterSectionObserverCallback,
				{ rootMargin: '320px 0px 0px' },
			);

			const elements = document.querySelectorAll('video, iframe');

			for (let i = 0; i < elements.length; i += 1) {
				intersectionObserver.observe(elements[i]);
			}

			return () => {
				intersectionObserver.disconnect();
			};
		}
	});
};
