export const getDatoPage = (pageProps: any) => {
	return (
		pageProps.homePage ||
		pageProps.page ||
		pageProps.spaceIndexPage ||
		pageProps.space ||
		pageProps.shopIndexPage ||
		pageProps.membershipIndexPage ||
		pageProps.membershipAnnouncementPage
	);
};
