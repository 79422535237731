/* eslint-disable camelcase */

import {
	DiceApiResponse,
	DiceEvent,
	DiceEventResponse,
	DiceEvents,
	DiceTicketType,
	DiceTicketTypeResponse,
	DiceTrack,
	DiceTrackResponse,
} from '@interfaces';

/**
 * A collection of mappers that convert the Dice api response data
 * to camelCase
 */

const mapTrackData = ({
	open_url,
	preview_url,
	...rest
}: DiceTrackResponse): DiceTrack => ({
	openUrl: open_url,
	previewUrl: preview_url,
	...rest,
});

const mapTicketTypeData = ({
	name,
	id,
	sold_out,
	price,
}: DiceTicketTypeResponse): DiceTicketType => ({
	name,
	id,
	soldOut: sold_out,
	price: {
		total: price.total,
		fees: price.fees,
		faceValue: price.face_value,
	},
});

export const mapEventData = ({
	int_id,
	perm_name,
	date_end,
	sale_start_date,
	sale_end_date,
	announcement_date,
	sold_out,
	event_images,
	spotify_tracks,
	apple_music_tracks,
	ticket_types,
	/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
	cities,
	age_limit,
	presented_by,
	type_tags,
	genre_tags,
	detailed_artists,
	destination_event_perm_name,
	external_url,
	...rest
}: DiceEventResponse): DiceEvent => ({
	intId: int_id,
	permName: perm_name,
	destinationEventPermName: destination_event_perm_name,
	dateEnd: date_end,
	saleStartDate: sale_start_date,
	saleEndDate: sale_end_date,
	announcementDate: announcement_date,
	soldOut: sold_out,
	eventImages: event_images,
	spotifyTracks: spotify_tracks.map(mapTrackData),
	appleMusicTracks: apple_music_tracks.map(mapTrackData),
	ticketTypes: ticket_types.map(mapTicketTypeData),
	ageLimit: age_limit,
	presentedBy: presented_by,
	typeTags: type_tags,
	genreTags: genre_tags,
	detailedArtists: detailed_artists,
	externalUrl: external_url,
	...rest,
});

export const mapApiEventsResponse = (
	response: DiceApiResponse,
): DiceEvents => ({
	...response,
	data: response.data.map((responseData) => {
		if (responseData.type === 'event') {
			return mapEventData(responseData);
		}
		throw new Error(`Unknown event type ${responseData}`);
	}),
});
