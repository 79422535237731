import { useEffect, useState } from 'react';

import { Dialog } from '@components/dialog/Dialog';

import { handleError } from '@common/utils/handleError';
import { isGtagAvailable } from '@common/utils/tracking';
import { useUser } from '@context/UserContext';
import { MembershipPopUpRecord } from '@interfaces';

import styles from './membership-pop-up.module.scss';
import { MembershipPopUpContent } from './MembershipPopUpContent';

interface MembershipPopUpProps {
	popUp: MembershipPopUpRecord;
}

export const MembershipPopUp = ({ popUp }: MembershipPopUpProps) => {
	const { subscription } = useUser();
	const [showPopUp, setShowPopUp] = useState(false);
	const [userRegion, setUserRegion] = useState<string | null>(null);
	const regionsToShowPopUp = ['NY', 'NJ', 'CT', 'MA'];
	const pagesToHidePopUp = ['membership', 'help'];

	useEffect(() => {
		const fetchUserRegion = async () => {
			const userRegionResponse = await fetch('/api/get-user-region');

			if (userRegionResponse.ok) {
				const region = await userRegionResponse.text();
				setUserRegion(region);
			} else {
				handleError('Failed to fetch user region');
			}
		};

		fetchUserRegion();
	}, []);

	useEffect(() => {
		if (subscription === undefined) return;

		const showPopUpFrom = window.localStorage.getItem(
			'show_membership_pop_up_from',
		);

		const neverShowPopup =
			window.localStorage.getItem('never_show_membership_pop_up') === 'true';

		const userIsInTargetRegion =
			userRegion && regionsToShowPopUp.includes(userRegion);

		const userIsReturning =
			showPopUpFrom && new Date(Number(showPopUpFrom)) < new Date();

		const showPopUpURL = new URLSearchParams(window.location.search).get(
			'showMembershipPopUp',
		);

		if (
			showPopUpURL ||
			(!subscription &&
				userIsInTargetRegion &&
				userIsReturning &&
				!neverShowPopup &&
				!pagesToHidePopUp.some((page) =>
					window.location.pathname.includes(page),
				))
		) {
			setShowPopUp(true);

			if (isGtagAvailable()) {
				gtag('event', 'pop-up', {
					event_category: 'Pop-up',
					event_label: 'Membership Pop-up appeared',
				});
			}
		}
	}, [userRegion, subscription]);

	const showAfter = popUp.delay * 1000;

	const onClose = () => {
		if (isGtagAvailable()) {
			gtag('event', 'close', {
				event_category: 'Pop-up',
				event_label: 'Membership Pop-up close',
			});
		}
		window.localStorage.setItem('never_show_membership_pop_up', 'true');
	};

	if (!showPopUp) {
		return null;
	}

	return (
		<Dialog
			classNames={{
				container: styles.container,
				dialog: styles.dialog,
				closeButton: styles.closeButton,
				title: 'sr-only',
			}}
			closeButtonLabel="Close membership pop-up"
			id="membership-pop-up-dialog"
			onClose={onClose}
			showAfter={showAfter}
			title="Membership pop-up"
			withScrollLock={false}
		>
			<MembershipPopUpContent popUp={popUp} />
		</Dialog>
	);
};
