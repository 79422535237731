import { useEffect } from 'react';
import { A11yDialog } from 'react-a11y-dialog';
import A11yDialogInstance from 'a11y-dialog';

import { Svg } from '@components/svg/Svg';

import { useScrollLock } from '@hooks/useScrollLock';
import { NavigationRecord } from '@interfaces';

import styles from './navigation-fullscreen.module.scss';
import { NavigationFullscreenFooter } from './NavigationFullscreenFooter';
import { NavigationFullscreenHeader } from './NavigationFullscreenHeader';
import { NavigationFullscreenMain } from './NavigationFullscreenMain';
import { NavigationFullscreenOpenButtonContent } from './NavigationFullscreenOpenButtonContent';

interface NavigationFullscreenProps {
	navigation: NavigationRecord;
	navigationFullscreenInstance?: A11yDialogInstance;
	setNavigationFullscreenInstance: (
		instance: A11yDialogInstance | undefined,
	) => void;
	overrideMobileOpenButtonColor?: 'sand';
}

export const NavigationFullscreen = ({
	navigation,
	navigationFullscreenInstance,
	setNavigationFullscreenInstance,
	overrideMobileOpenButtonColor,
}: NavigationFullscreenProps) => {
	const { lockScroll, unlockScroll } = useScrollLock();
	const {
		primary,
		newsletterSection,
		location,
		exploreLinks,
		aboutLinks,
		mapDrawing,
		socialLinks,
		infoPages,
		eventLinks,
	} = navigation;
	const internalLinkSelector = 'a[href^="/"]';

	// The `has-dialog` class is added so other dialogs (like the newsletter popup) can check if another dialog is already open
	const hasDialogClassName = 'has-dialog';

	const openDialog = () => {
		navigationFullscreenInstance?.show();
	};

	const closeDialog = () => {
		navigationFullscreenInstance?.hide();
	};

	const onShowDialog = ({ target }: Event) => {
		if (!(target instanceof Element)) return;

		const dialogElement = target;

		document.body.classList.add(hasDialogClassName);

		const linkElements = dialogElement.querySelectorAll(internalLinkSelector);

		linkElements.forEach((closeDialogElement) => {
			closeDialogElement.addEventListener('click', closeDialog);
		});

		lockScroll();
	};

	const onHideDialog = ({ target }: Event) => {
		if (!(target instanceof Element)) return;

		const dialogElement = target;

		document.body.classList.remove(hasDialogClassName);

		const linkElements = dialogElement.querySelectorAll(internalLinkSelector);

		linkElements.forEach((closeDialogElement) => {
			closeDialogElement.removeEventListener('click', closeDialog);
		});

		unlockScroll();
	};

	useEffect(() => {
		if (navigationFullscreenInstance) {
			navigationFullscreenInstance.on('show', onShowDialog);
			navigationFullscreenInstance.on('hide', onHideDialog);

			return () => {
				navigationFullscreenInstance.destroy();
			};
		}
	}, [navigationFullscreenInstance]);

	return (
		<>
			<button
				aria-label="Open fullscreen navigation"
				className={styles.openButton}
				onClick={openDialog}
				type="button"
			>
				<NavigationFullscreenOpenButtonContent
					overrideMobileOpenButtonColor={overrideMobileOpenButtonColor}
				/>
			</button>
			<A11yDialog
				classNames={{
					container: styles.container,
					overlay: styles.overlay,
					dialog: styles.dialog,
					closeButton: styles.closeButton,
					title: 'sr-only',
				}}
				closeButtonContent={<Svg id="close" />}
				closeButtonLabel="Close fullscreen navigation"
				closeButtonPosition="last"
				dialogRef={(instance: A11yDialogInstance | undefined) => {
					if (instance) {
						setNavigationFullscreenInstance(instance);
					}
				}}
				id="navigation-fullscreen-dialog"
				title="Fullscreen navigation"
			>
				<NavigationFullscreenHeader className={styles.header} />
				<NavigationFullscreenMain
					aboutLinks={aboutLinks}
					aria-labelledby="navigation-fullscreen-toggle"
					className={styles.main}
					closeNavigation={closeDialog}
					eventLinks={eventLinks}
					exploreLinks={exploreLinks}
					id="navigation-fullscreen-content"
					location={location}
					mapDrawing={mapDrawing}
					newsletterSection={newsletterSection}
					primary={primary}
					socialLinks={socialLinks}
				/>
				<NavigationFullscreenFooter
					className={styles.footer}
					infoPages={infoPages}
				/>
			</A11yDialog>
		</>
	);
};
