import { Fragment, ReactElement } from 'react';

export const splitHeading = (
	heading: string,
	separator: ReactElement | null = null,
	insertBefore: React.ReactNode[] = [],
	insertAfter: React.ReactNode[] = [],
): ReactElement => {
	const lines = heading
		.split('\n')
		.map((line) => line.trim())
		.filter((line) => line.length);

	const renderSeparator = (index: number) =>
		separator && lines.length > 1 && index !== lines.length - 1
			? separator
			: null;

	const jsxElements = lines.reduce<JSX.Element[]>(
		(accumulator, line, index) => {
			const spanKey = `${index}-line`;
			const fragmentKey = `${index}-fragment`;
			const nextAccumulator = [...accumulator];

			if (index) {
				// Add a space to prevent lines from sticking together
				nextAccumulator.push(<Fragment key={fragmentKey}> </Fragment>);
			}

			nextAccumulator.push(
				<span key={spanKey}>
					{insertBefore[index]}
					{line}
					{insertAfter[index]}
					{renderSeparator(index)}
				</span>,
			);

			return nextAccumulator;
		},
		[],
	);

	return <>{jsxElements}</>;
};
