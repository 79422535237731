export const cartFragment = `
	fragment Cart on Cart {
		id
		createdAt
		updatedAt
		totalQuantity
		checkoutUrl
		cost {
			subtotalAmount {
				...Money
			}
			totalAmount {
				...Money
			}
		}
		lines(first: $lineCount) {
			edges {
				node {
					id
					quantity
					merchandise {
						... on ProductVariant {
							id
							title
							image {
								...Image
							}
							priceV2 {
								...Money
							}
							product {
								title
								handle
								options {
									...ProductOption
								}
							}
						}
					}
				}
			}
		}
		discountAllocations {
			discountedAmount {
				...Money
			}
		}
		discountCodes {
			applicable
			code
		}
	}
`;
