import { CART_LINE_COUNT } from '@data/constants';
import {
	cartFragment,
	imageFragment,
	moneyFragment,
	productOptionFragment,
} from '@data/shopify/fragments';
import { shopifyClient } from '@data/shopify/shopifyClient';
import { ShopifyCartDiscountCodesUpdateResponse } from '@interfaces';

export const cartDiscountCodesUpdate = async (
	cartId: string,
	discountCodes: string[],
) => {
	return shopifyClient.request<ShopifyCartDiscountCodesUpdateResponse>(
		`
			mutation cartDiscountCodesUpdate($cartId: ID!, $discountCodes: [String!], $lineCount: Int!) {
				cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
					cart {
						...Cart
					}
				}
			}
			${cartFragment}
			${imageFragment}
			${moneyFragment}
			${productOptionFragment}
		`,
		{
			cartId,
			discountCodes,
			lineCount: CART_LINE_COUNT,
		},
	);
};
