/* eslint-disable camelcase */
import { useRouter } from 'next/router';
import classNames from 'classnames';

import { NewsletterSection } from '@components/newsletterSection/NewsletterSection';

import { getSubscriptionMetadata } from '@common/utils/membership';
import { getNavigationAnchor } from '@common/utils/navigation';
import { useUser } from '@context/UserContext';
import { NavigationRecord } from '@interfaces';

import { MapDrawing } from './MapDrawing';
import styles from './navigation-fullscreen-main.module.scss';
import { NavigationAnchorPrimary } from './NavigationAnchorPrimary';
import { NavigationLinkBlock } from './NavigationLinkBlock';
import { NavigationLocation } from './NavigationLocation';

interface NavigationFullscreenMainProps
	extends Pick<
		NavigationRecord,
		| 'id'
		| 'primary'
		| 'location'
		| 'newsletterSection'
		| 'exploreLinks'
		| 'location'
		| 'exploreLinks'
		| 'aboutLinks'
		| 'mapDrawing'
		| 'socialLinks'
		| 'eventLinks'
	> {
	className?: string;
	closeNavigation: () => void;
}

export const NavigationFullscreenMain = ({
	id,
	primary,
	className,
	newsletterSection,
	location,
	exploreLinks,
	aboutLinks,
	mapDrawing,
	socialLinks,
	eventLinks,
}: NavigationFullscreenMainProps) => {
	const router = useRouter();
	const { subscription } = useUser();
	const { tier_level } = getSubscriptionMetadata(subscription);

	return (
		<div
			className={classNames(className, styles['navigation-fullscreen-main'])}
			id={id}
		>
			<ul
				className={classNames(
					styles['navigation-fullscreen-main__primary'],
					'list-unstyled',
				)}
			>
				{subscription && tier_level !== '1' ? (
					<li key="My events">
						<NavigationAnchorPrimary url="/my-events">
							My events
						</NavigationAnchorPrimary>
					</li>
				) : null}
				{primary.links.map((link) => {
					const navigationAnchor = getNavigationAnchor(router, link);

					if (navigationAnchor) {
						return (
							<li key={link.id}>
								<NavigationAnchorPrimary {...navigationAnchor}>
									{navigationAnchor.title}
								</NavigationAnchorPrimary>
							</li>
						);
					}

					return null;
				})}
			</ul>
			<div className={styles['navigation-fullscreen-main__wrapper']}>
				<div className={styles['navigation-fullscreen-main__link-blocks']}>
					<NavigationLinkBlock
						className={
							styles['navigation-fullscreen-main__link-blocks--events']
						}
						{...eventLinks}
					/>
					<NavigationLinkBlock
						className={
							styles['navigation-fullscreen-main__link-blocks--social']
						}
						external
						{...socialLinks}
					/>
					<NavigationLinkBlock
						className={
							styles['navigation-fullscreen-main__link-blocks--explore']
						}
						{...exploreLinks}
					/>
					<NavigationLinkBlock
						className={styles['navigation-fullscreen-main__link-blocks--about']}
						{...aboutLinks}
					/>
					<NavigationLocation
						className={
							styles['navigation-fullscreen-main__link-blocks--location']
						}
						{...location}
					/>
					<MapDrawing
						className={styles['navigation-fullscreen-main__link-blocks--map']}
						mapLinkLabel={location.mapLinkLabel}
						mapLinkUrl={location.mapLinkUrl}
						{...mapDrawing}
					/>
				</div>
				{newsletterSection ? (
					<NewsletterSection
						className={styles['navigation-fullscreen-main__newsletter']}
						location="website navigation"
						{...newsletterSection}
					/>
				) : null}
			</div>
		</div>
	);
};
