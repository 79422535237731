import classNames from 'classnames';

import { ButtonAnchor } from '@components/button/ButtonAnchor';

import { formatPrice } from '@common/utils/price';
import { ShopifyCart } from '@interfaces';

import styles from './cart-footer.module.scss';

interface CartFooterProps {
	className?: string;
	cart: ShopifyCart;
}

export const CartFooter = ({ className, cart }: CartFooterProps) => {
	const formattedTotalAmount = formatPrice({
		cents: Number(cart.cost.totalAmount.amount) * 100,
	});

	const formattedSubtotalAmount = formatPrice({
		cents: Number(cart.cost.subtotalAmount.amount) * 100,
	});

	// Calculate total discounted amount on the cart
	const discountedAmount = cart.discountAllocations.reduce(
		(accumulator, { discountedAmount: { amount } }) => {
			return accumulator + Number(amount);
		},
		0,
	);

	const formattedDiscountedAmount = formatPrice({
		cents: Number(discountedAmount) * 100,
	});

	const trackClarityEvent = () => {
		if (window.clarity) {
			window.clarity('event', 'checkoutCart');
		}
	};

	return (
		<div className={classNames(styles.footer, className)}>
			{discountedAmount ? (
				<>
					<p className={styles.subtotal}>
						<span>Subtotal</span>
						<span>{formattedSubtotalAmount}</span>
					</p>
					<p className={styles.discount}>
						<span>Member Discount</span>
						<span>- {formattedDiscountedAmount}</span>
					</p>
				</>
			) : null}
			<p className={styles.total}>
				<span>Total</span>
				<span>{formattedTotalAmount}</span>
			</p>
			<p className={styles.note}>
				Shipping and taxes are calculated at checkout
			</p>
			<ButtonAnchor
				className={styles.link}
				href={cart.checkoutUrl}
				onClick={trackClarityEvent}
			>
				Checkout
			</ButtonAnchor>
		</div>
	);
};
