import Link from 'next/link';
import { useRouter } from 'next/router';

import styles from './draft-banner.module.scss';

export const DraftBanner = () => {
	const router = useRouter();

	return (
		<div className={styles['draft-banner']}>
			<span>You are currently in draft mode. </span>
			<Link
				className="anchor"
				href={{
					pathname: '/api/exit-draft-mode',
					query: { pathname: router.asPath },
				}}
				prefetch={false}
			>
				Exit Draft Mode
			</Link>
		</div>
	);
};
