import {
	NavigationAnchor,
	NavigationAnchorProps,
} from '@components/navigation/NavigationAnchor';

import { useUser } from '@context/UserContext';

import styles from './membership-anchor.module.scss';

export const MembershipAnchor = (props: NavigationAnchorProps) => {
	const { children } = props;
	const { authUser } = useUser();

	if (authUser) return null;

	return (
		<NavigationAnchor {...props} className={styles['membership-anchor']}>
			{children}
		</NavigationAnchor>
	);
};
