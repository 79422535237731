import { Markdown } from '@components/markdown/Markdown';

import styles from './message.module.scss';

interface MessageProps {
	heading: string;
	text: string;
}

export const Message = ({ heading, text }: MessageProps) => (
	<article className={styles.message}>
		<h1 className={styles.message__heading}>
			<Markdown text={heading} />
		</h1>
		<Markdown className={styles.message__text} text={text} />
	</article>
);
