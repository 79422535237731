import Link from 'next/link';
import classNames from 'classnames';

export interface NavigationAnchorProps {
	url: string;
	isActive?: boolean;
	children?: React.ReactNode;
	rel?: string;
	className?: string;
}

export const NavigationAnchor = ({
	url,
	children,
	isActive = false,
	rel,
	className,
}: NavigationAnchorProps) => {
	return (
		<Link
			className={classNames(className, 'navigation-anchor', {
				'navigation-anchor--is-active': isActive,
			})}
			href={url}
			rel={rel}
		>
			{children}
		</Link>
	);
};
