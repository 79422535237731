import React from 'react';
import { NextPage } from 'next';

import { Error } from '@components/error/Error';

interface ErrorPageProps {
	statusCode?: number;
}

const ErrorPage: NextPage<ErrorPageProps> = ({ statusCode }) => {
	if (statusCode === 429) {
		return (
			<Error
				heading="Elsewhere will be back soon!"
				text="Sorry for the inconvenience but our website is currently undergoing maintenance. We expect to be back shortly."
				title="Maintenance"
			/>
		);
	}
	if (statusCode === 404) {
		return (
			<Error
				heading="404<br>Page Not Found"
				text="Looks like the page you are looking for doesn't exist, please head to the [homepage](/)."
				title="Not found"
			/>
		);
	}
	return (
		<Error
			heading="Something went wrong"
			text="Sorry, things don't appear to be working at the moment. Please try again later."
			title="Error"
		/>
	);
};

ErrorPage.getInitialProps = ({ res, err }): ErrorPageProps => {
	const statusCode = res?.statusCode || err?.statusCode || 404;
	return { statusCode };
};

export default ErrorPage;
