import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';

import { splitFullName } from '@common/utils/transform';
import { useUser } from '@context/UserContext';

const getEventId = () => {
	const eventWrapper = document.querySelector(
		'[itemType="https://schema.org/MusicEvent"]',
	);

	return eventWrapper ? eventWrapper.getAttribute('id') : undefined;
};

interface MetaPixelProps {
	pixelId: string;
}

export const MetaPixel = ({ pixelId }: MetaPixelProps) => {
	const router = useRouter();
	const user = useUser();

	const routeChangeHandler = () => {
		fbq('track', 'PageView');

		// On an event detail page track the `ViewContent` event
		const eventId = getEventId();

		if (eventId) {
			fbq('track', 'ViewContent', {
				content_type: 'product',
				content_ids: eventId,
			});
		}
	};

	useEffect(() => {
		const { authUser, userRecord } = user;

		if (authUser?.email && userRecord?.full_name) {
			const { firstName, lastName } = splitFullName(userRecord.full_name);

			fbq('init', pixelId, {
				em: authUser.email,
				fn: firstName,
				ln: lastName,
			});
		}
	}, [user.authUser, user.userRecord]);

	useEffect(() => {
		// Handle subsequent page visits
		router.events.on('routeChangeComplete', routeChangeHandler);

		return () => {
			router.events.off('routeChangeComplete', routeChangeHandler);
		};
	}, [router.events]);

	useEffect(() => {
		// Initialize Pixel
		fbq('init', pixelId);

		// Handle initial page visit
		routeChangeHandler();
	}, []);

	return (
		<Script id="facebook-analytics" strategy="afterInteractive">
			{/* https://developers.facebook.com/docs/facebook-pixel/implementation */}
			{`
				!function(f,b,e,v,n,t,s)
				{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
				n.callMethod.apply(n,arguments):n.queue.push(arguments)};
				if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
				n.queue=[];t=b.createElement(e);t.async=!0;
				t.src=v;s=b.getElementsByTagName(e)[0];
				s.parentNode.insertBefore(t,s)}(window, document,'script',
				'https://connect.facebook.net/en_US/fbevents.js');
			`}
		</Script>
	);
};
