import { ShopifyGetCollectionProductListResponse } from 'src/interfaces/shopify';

import {
	imageFragment,
	moneyFragment,
	productOptionFragment,
	productPreviewFragment,
	productPriceRangeFragment,
} from '@data/shopify/fragments';
import { shopifyClient } from '@data/shopify/shopifyClient';

interface GetCollectionProductListOptions {
	productCount?: number;
	afterCursor?: string;
}

export const getCollectionProductList = async (
	handle: string,
	options?: GetCollectionProductListOptions,
) => {
	const { productCount = 20, afterCursor = null } = options || {};

	return shopifyClient.request<ShopifyGetCollectionProductListResponse>(
		`
			query getCollectionProductList($handle: String!, $productCount: Int!, $afterCursor: String) {
				collection(handle: $handle) {
					products(first: $productCount, after: $afterCursor) {
						edges {
							cursor
							node {
								...ProductPreview
							}
						}
					}
				}
			}
			${imageFragment}
			${moneyFragment}
			${productOptionFragment}
			${productPreviewFragment}
			${productPriceRangeFragment}
		`,
		{
			handle,
			productCount,
			afterCursor,
		},
	);
};
