import { captureException } from '@sentry/nextjs';

import { isProduction } from './environment';
import { toErrorWithMessage } from './errorMessage';

export function handleError(error: unknown) {
	if (isProduction()) {
		captureException(error);
	} else {
		throw toErrorWithMessage(error);
	}
}
