import Link from 'next/link';
import classNames from 'classnames';

import { ShopImage } from '@components/shopImage/ShopImage';
import { ShopPriceTag } from '@components/shopPriceTag/ShopPriceTag';

import { getProductOptionCount, updateCartLines } from '@common/utils/shopify';
import { useCart } from '@context/CartContext';
import { SHOP_PRODUCTS_URL } from '@data/constants';
import { ShopifyCart, ShopifyCartLineEdge } from '@interfaces';

import styles from './cart-item.module.scss';

interface CartItemProps {
	className?: string;
	cart: ShopifyCart;
	cartLineEdge: ShopifyCartLineEdge;
}

export const CartItem = ({ className, cart, cartLineEdge }: CartItemProps) => {
	const { setCart } = useCart();

	const {
		node: { id, quantity, merchandise },
	} = cartLineEdge;

	const { product } = merchandise;
	const href = `${SHOP_PRODUCTS_URL}/${product.handle}`;
	const productOptionCount = getProductOptionCount(product.options);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		// Reduce quantity by one
		const lines = [
			{
				id,
				merchandiseId: merchandise.id,
				quantity: quantity - 1,
			},
		];

		const nextCart = await updateCartLines({
			cartId: cart.id,
			lines,
		});

		nextCart.refreshedAt = new Date().toISOString();

		setCart(nextCart);
	};

	return (
		<article className={classNames(styles.item, className)}>
			<header className={styles.header}>
				<h2 className={styles.title}>
					<Link className={classNames(styles.link, 'breakout')} href={href}>
						{product.title}
					</Link>
				</h2>
				{productOptionCount > 1 ? (
					<p className={styles.subtitle}>{merchandise.title}</p>
				) : null}
				<form onSubmit={handleSubmit}>
					<button className={styles['remove-button']} type="submit">
						Remove
					</button>
				</form>
			</header>
			<div className={styles.body}>
				<div className={styles['image-frame']}>
					{merchandise.image ? (
						<ShopImage
							image={merchandise.image}
							sizes="(min-width: 26rem) 11rem, 40vw"
						/>
					) : null}
				</div>
				<ShopPriceTag
					className={classNames(styles['price-tag'], 'font-t3-title')}
					money={merchandise.priceV2}
				/>
			</div>
		</article>
	);
};
