import {
	cartCreate,
	cartDiscountCodesUpdate,
	cartLinesAdd,
	cartLinesUpdate,
} from '@data/shopify/queries';
import { ShopifyCartLineInput, ShopifyProductOption } from '@interfaces';

interface AddCartLinesParams {
	cartId?: string;
	lines: ShopifyCartLineInput[];
}

export const addCartLines = async ({
	cartId = undefined,
	lines,
}: AddCartLinesParams) => {
	if (!cartId) {
		const {
			cartCreate: { cart: createdCart },
		} = await cartCreate(lines);

		return createdCart;
	}

	const {
		cartLinesAdd: { cart },
	} = await cartLinesAdd(cartId, lines);

	return cart;
};

interface UpdateCartLinesParams {
	cartId: string;
	lines: ShopifyCartLineInput[];
}

export const updateCartLines = async ({
	cartId,
	lines,
}: UpdateCartLinesParams) => {
	const {
		cartLinesUpdate: { cart },
	} = await cartLinesUpdate(cartId, lines);

	return cart;
};

interface UpdateCartDiscountCodes {
	cartId: string;
	discountCodes: string[];
}

export const updateCartDiscountCodes = async ({
	cartId,
	discountCodes,
}: UpdateCartDiscountCodes) => {
	const {
		cartDiscountCodesUpdate: { cart },
	} = await cartDiscountCodesUpdate(cartId, discountCodes);

	return cart;
};

export const getProductOptionCount = (options: ShopifyProductOption[]) => {
	return options.reduce((count, option) => {
		return count + option.values.length;
	}, 0);
};
