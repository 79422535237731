import Head from 'next/head';

import { Message } from '@components/message/Message';

interface MaintenanceProps {
	title: string;
	heading: string;
	text: string;
}

export const Error = ({ title, heading, text }: MaintenanceProps) => (
	<>
		<Head>
			<title>{`Elsewhere | ${title}`}</title>
		</Head>
		<Message heading={heading} text={text} />
	</>
);
