import {
	createContext,
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useState,
} from 'react';

import { getTimeSince } from '@common/utils/date';
import { getSubscriptionMetadata } from '@common/utils/membership';
import { updateCartDiscountCodes } from '@common/utils/shopify';
import { SHOP_CART_STORAGE_KEY } from '@data/constants';
import { getCart } from '@data/shopify/queries';
import { useSetInterval } from '@hooks/useSetInterval';
import { ShopifyCart } from '@interfaces';

import { useUser } from './UserContext';

interface CartContextInterface {
	cart: ShopifyCart | null;
	setCart: Dispatch<SetStateAction<ShopifyCart | null>>;
}

export const CartContext = createContext<CartContextInterface>({
	cart: null,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setCart: () => {},
});

interface CartContextProviderProps {
	children?: React.ReactNode;
}

export const CartContextProvider = ({ children }: CartContextProviderProps) => {
	const refreshCartAfter = {
		minutes: 5,
		milliseconds: 5 * 60 * 1000,
	};
	const [cart, setCart] = useState<ShopifyCart | null>(null);
	const { subscription } = useUser();

	const clearCart = () => {
		window.localStorage.removeItem(SHOP_CART_STORAGE_KEY);

		setCart(null);
	};

	const refreshCart = async (currentCart: ShopifyCart) => {
		const { hours: hoursSinceCreated } = getTimeSince(currentCart.createdAt);

		// Remove cart if it has been created over 24 hours ago
		if (hoursSinceCreated < 24) {
			const { minutes: minutesSinceRefreshed } = getTimeSince(
				currentCart.refreshedAt,
			);

			if (minutesSinceRefreshed > refreshCartAfter.minutes) {
				const { cart: nextCart } = await getCart(currentCart.id);

				if (!nextCart) {
					// Cart might have been deleted after checkout
					clearCart();
				} else {
					nextCart.refreshedAt = new Date().toISOString();

					setCart(nextCart);
				}
			} else {
				// Cart is still fresh, don't refresh
				setCart(currentCart);
			}
		} else {
			clearCart();
		}
	};

	useSetInterval(() => {
		if (cart) {
			refreshCart(cart);
		}
	}, refreshCartAfter.milliseconds);

	useEffect(() => {
		// Retrieve cart from localStorage
		const retrievedCartData = window.localStorage.getItem(
			SHOP_CART_STORAGE_KEY,
		);

		if (retrievedCartData) {
			try {
				const retrievedCart: ShopifyCart = JSON.parse(retrievedCartData);

				// Refresh the retrieved cart it on initial pageload
				refreshCart(retrievedCart);
			} catch {
				clearCart();
			}
		}
	}, []);

	useEffect(() => {
		if (cart) {
			window.localStorage.setItem(SHOP_CART_STORAGE_KEY, JSON.stringify(cart));
		}
	}, [cart]);

	useEffect(() => {
		if (!cart) return;

		const updateDiscountCodes = async (discountCodes: string[]) => {
			const nextCart = await updateCartDiscountCodes({
				cartId: cart.id,
				discountCodes,
			});

			setCart(nextCart);
		};

		const { shopify_discount_code: shopifyDiscountCode } =
			getSubscriptionMetadata(subscription);

		updateDiscountCodes(shopifyDiscountCode ? [shopifyDiscountCode] : []);
	}, [subscription, cart !== null]);

	return (
		<CartContext.Provider value={{ cart, setCart }}>
			{children}
		</CartContext.Provider>
	);
};

export const useCart = (): CartContextInterface => {
	return useContext(CartContext);
};
