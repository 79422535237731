import classNames from 'classnames';

import { splitHeading } from '@common/utils/splitHeading';

import styles from './split-heading.module.scss';

interface SplitHeadingProps {
	className?: string;
	heading: string;
	separator?: React.ReactElement;
	id?: string;
	tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
	insertBefore?: React.ReactNode[];
	insertAfter?: React.ReactNode[];
	centerOnMobile?: boolean;
}

export const SplitHeading = ({
	className,
	heading,
	separator,
	id,
	tag,
	insertBefore,
	insertAfter,
	centerOnMobile,
}: SplitHeadingProps) => {
	const Tag = tag as keyof JSX.IntrinsicElements;

	return (
		<Tag
			className={classNames(
				className,
				styles['split-heading'],
				centerOnMobile ? styles['center-on-mobile'] : null,
			)}
			id={id}
		>
			{splitHeading(heading, separator, insertBefore, insertAfter)}
		</Tag>
	);
};
