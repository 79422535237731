import { useEffect, useState } from 'react';

import { Dialog } from '@components/dialog/Dialog';

import { handleError } from '@common/utils/handleError';
import { isGtagAvailable } from '@common/utils/tracking';
import { useUser } from '@context/UserContext';
import {
	ApiGetMailchimpSubscriptionStatusErrorResponse,
	ApiGetMailchimpSubscriptionStatusSuccessResponse,
} from '@interfaces';

import styles from './unsubscribed-warning-banner.module.scss';
import { UnsubscribedWarningBannerContent } from './UnsubscribedWarningBannerContent';

export const UnsubscribedWarningBanner = () => {
	const { authUser } = useUser();

	const [showBanner, setShowBanner] = useState(false);
	const bannerCloseCountLocalStorageItem =
		'unsubscribed_warning_banner_close_count';

	useEffect(() => {
		const showBannerURLParam = new URLSearchParams(window.location.search).get(
			'showUnsubscribedWarningBanner',
		);

		let neverShowBanner = false;

		try {
			neverShowBanner =
				Number(window.localStorage.getItem(bannerCloseCountLocalStorageItem)) >
				1;
		} catch (error) {
			handleError(error);
		}

		if ((authUser?.email && !neverShowBanner) || showBannerURLParam) {
			if (showBannerURLParam) {
				setShowBanner(true);
				return;
			}

			fetch('/api/mailchimp/get-subscription-status', {
				body: JSON.stringify({ email: authUser?.email }),
				headers: {
					'Content-Type': 'application/json',
				},
				method: 'POST',
			})
				.then((response) => {
					if (response.ok) {
						return response.json() as Promise<
							| ApiGetMailchimpSubscriptionStatusErrorResponse
							| ApiGetMailchimpSubscriptionStatusSuccessResponse
						>;
					}
				})
				.then((data) => {
					if (data) {
						if ('error' in data) {
							throw new Error(data.error);
						}

						if ('status' in data) {
							const isSubscribed = data.status === 'subscribed';
							const isUnregistered = data.status === 'unregistered';

							if (!isSubscribed && !isUnregistered) {
								setShowBanner(true);

								if (isGtagAvailable()) {
									gtag('event', 'banner', {
										event_category: 'Unsubscribed Warning Banner',
										event_label: 'Unsubscribed Warning Banner appeared',
									});
								}
							}
						}
					}
				})
				.catch((error) => {
					handleError(error);
				});
		}
	}, [authUser]);

	const onClose = () => {
		if (isGtagAvailable()) {
			gtag('event', 'close', {
				event_category: 'Unsubscribed Warning Banner',
				event_label: 'Unsubscribed Warning Banner closed',
			});
		}
		try {
			const closeCount = window.localStorage.getItem(
				bannerCloseCountLocalStorageItem,
			);
			if (closeCount) {
				window.localStorage.setItem(
					bannerCloseCountLocalStorageItem,
					String(Number(closeCount) + 1),
				);
			} else {
				window.localStorage.setItem(
					bannerCloseCountLocalStorageItem,
					String(1),
				);
			}
		} catch (error) {
			handleError(error);
		}
	};

	const delay = 1000;

	if (!showBanner) {
		return null;
	}

	if (!authUser?.email) {
		return null;
	}

	return (
		<Dialog
			classNames={{
				container: styles.container,
				dialog: styles.dialog,
				closeButton: styles.closeButton,
				title: 'sr-only',
			}}
			closeButtonLabel="Close unsubscribed warning banner"
			id="unsubscribed-warning-banner-dialog"
			onClose={onClose}
			showAfter={delay}
			title="Unsubscribed warning banner"
			withScrollLock={false}
		>
			<UnsubscribedWarningBannerContent email={authUser.email} />
		</Dialog>
	);
};
