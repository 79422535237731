import { CSSProperties, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { Svg } from '@components/svg/Svg';

import { useFocusTrap } from '@hooks/useFocusTrap';
import { useScrollLock } from '@hooks/useScrollLock';

import styles from './reservation-widget.module.scss';
import { useReservationWidget } from './reservationWidgetContext';

export const ReservationWidget = () => {
	const { isVisible, src, hideWidget, options } = useReservationWidget();
	const [isLoaded, setIsLoaded] = useState(false);
	const widgetRef = useRef(null);

	useFocusTrap(widgetRef, isVisible);
	const { lockScroll, unlockScroll } = useScrollLock();

	const onLoad = () => {
		setIsLoaded(true);
	};
	const onClose = () => {
		hideWidget();
		setIsLoaded(false);
	};

	useEffect(() => {
		if (isVisible) {
			setIsLoaded(false);
			lockScroll();
		} else {
			unlockScroll();
		}
	}, [isVisible]);
	return (
		<div
			aria-hidden={!isVisible}
			aria-label="Reservation widget"
			aria-modal={isVisible}
			className={classNames(styles['reservation-widget'], {
				[styles['reservation-widget--is-visible']]: isVisible,
				[styles['reservation-widget--is-loaded']]: isLoaded,
			})}
			ref={widgetRef}
			style={
				{
					'--header-background':
						options.type === 'table'
							? 'var(--color-brand-pink)'
							: 'var(--color-brand-acid-green)',
				} as CSSProperties
			}
		>
			<div className={classNames(styles['reservation-widget__loader'])} />
			<div className={classNames(styles['reservation-widget__modal'])}>
				<button
					aria-label="Close reservation widget"
					className={classNames(styles['reservation-widget__close'])}
					onClick={onClose}
					type="button"
				>
					<Svg id="plus" />
				</button>
				{isVisible ? (
					<article
						className={classNames(styles['reservation-widget__content'])}
					>
						{options.title && options.description ? (
							<header
								className={classNames(
									styles['reservation-widget__header'],
									'stack-sm',
								)}
							>
								<h3 className="font-t4-bold">{options.title}</h3>
								<p className="font-t5">{options.description}</p>
							</header>
						) : null}
						<iframe
							id="iframe"
							onLoad={onLoad}
							src={src}
							title="Elsewhere table reservation"
						/>
					</article>
				) : null}
			</div>
		</div>
	);
};
