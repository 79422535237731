import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

export const useAxe = (): void => {
	useEffect(() => {
		if (process.env.NODE_ENV !== 'production') {
			import('@axe-core/react').then((axe) =>
				axe.default(React, ReactDOM, 1000),
			);
		}
	}, []);
};
