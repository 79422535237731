import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { isProduction } from '@common/utils/environment';
import { useMediaQuery } from '@hooks/useMediaQuery';
import { DatoMedia } from '@interfaces';

import styles from './video.module.scss';

interface VideoProps {
	captions?: DatoMedia;
	className?: string;
	hasAutoPlay?: boolean;
	hasControls?: boolean;
	isLoop?: boolean;
	isMuted?: boolean;
	media: DatoMedia;
}

export const Video = ({
	captions,
	className,
	hasAutoPlay = false,
	hasControls = true,
	isLoop = false,
	isMuted = false,
	media: {
		width,
		height,
		video: { mp4Url, thumbnailUrl },
	},
}: VideoProps) => {
	const [isLazyLoaded, setIsLazyLoaded] = useState(true);
	const isReducedMotionPreferred = useMediaQuery(
		'(prefers-reduced-motion: reduce)',
	);
	const isAutoPlaying =
		isProduction() && hasAutoPlay && !isReducedMotionPreferred;

	useEffect(() => {
		const isIntersectionObserverSupported = 'IntersectionObserver' in window;

		if (!isIntersectionObserverSupported) {
			setIsLazyLoaded(false);
		}
	}, []);

	return (
		<video
			autoPlay={isAutoPlaying}
			className={classNames(className, styles.video)}
			controls={hasControls}
			crossOrigin={captions?.url ? 'anonymous' : undefined}
			data-src={isLazyLoaded ? mp4Url : undefined}
			height={height}
			loop={isLoop}
			muted={isMuted}
			playsInline
			poster={thumbnailUrl}
			preload="none"
			src={isLazyLoaded ? undefined : mp4Url}
			width={width}
		>
			{captions?.url ? (
				<track default kind="captions" src={captions.url} srcLang="en" />
			) : null}
		</video>
	);
};
