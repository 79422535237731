import { Svg } from '@components/svg/Svg';

import styles from './arrow-decoration.module.scss';

export const ArrowDecoration = () => {
	return (
		<Svg
			aria-hidden="true"
			className={styles['arrow-decoration']}
			id="hand-drawn-arrow-1"
		/>
	);
};
