import { Svg } from '@components/svg/Svg';

import styles from './cart-open-button-content.module.scss';

interface CartOpenButtonContentProps {
	quantity: number;
}

export const CartOpenButtonContent = ({
	quantity,
}: CartOpenButtonContentProps) => {
	return (
		<div className={styles.content}>
			<span className={styles.quantity}>{quantity}</span>
			<Svg aria-hidden="true" className={styles.icon} id="cart" />
		</div>
	);
};
