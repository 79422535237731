import { NextRouter } from 'next/router';

import { NavigationLink, NavLinkRecord, NavPageRecord } from '@interfaces';

import { neverError } from './neverError';

export const getIsActiveUrl = (router: NextRouter, url: string) => {
	return url.length < router.asPath.length
		? url.startsWith(router.asPath)
		: router.asPath.startsWith(url);
};

export const getNavigationAnchor = (
	router: NextRouter,
	link: NavigationLink,
) => {
	const typeNameKey = '__typename';

	switch (link[typeNameKey]) {
		case 'NavLinkRecord': {
			const { url, title, backgroundColor } = link as NavLinkRecord;
			const isActive = getIsActiveUrl(router, url);

			return { backgroundColor, isActive, url, title };
		}
		case 'NavPageRecord': {
			const { page } = link as NavPageRecord;
			const { __typename, slug, title, backgroundColor } = page;

			if (__typename === undefined) return null;

			const url =
				__typename === 'InfoPageRecord' ? `/info/${slug}` : `/${slug}`;
			const isActive = getIsActiveUrl(router, `/${slug}`);

			return { backgroundColor, isActive, url, title };
		}
		case undefined: {
			// new nav records in dato return as empty objects
			// we don’t want to display an error in that case
			return null;
		}
		default: {
			throw neverError('Unknown navigation link module', module);
		}
	}
};
