import { CART_LINE_COUNT } from '@data/constants';
import {
	cartFragment,
	imageFragment,
	moneyFragment,
	productOptionFragment,
} from '@data/shopify/fragments';
import { shopifyClient } from '@data/shopify/shopifyClient';
import { ShopifyCartLineInput, ShopifyCartLinesAddResponse } from '@interfaces';

export const cartLinesAdd = async (
	cartId: string,
	lines: ShopifyCartLineInput[],
) => {
	return shopifyClient.request<ShopifyCartLinesAddResponse>(
		`
			mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!, $lineCount: Int!) {
				cartLinesAdd(cartId: $cartId, lines: $lines) {
					cart {
						...Cart
					}
				}
			}
			${cartFragment}
			${imageFragment}
			${moneyFragment}
			${productOptionFragment}
		`,
		{
			cartId,
			lines,
			lineCount: CART_LINE_COUNT,
		},
	);
};
