import classNames from 'classnames';
import { ShopifyMoney } from 'src/interfaces/shopify';

import { formatPrice } from '@common/utils/price';

import styles from './shop-price-tag.module.scss';

interface ShopPriceTagProps {
	className?: string;
	money: ShopifyMoney;
}

export const ShopPriceTag = ({
	className,
	money: { amount },
}: ShopPriceTagProps) => {
	const formattedAmount = formatPrice({
		cents: Number(amount) * 100,
		maximumFractionDigits: 0,
		minimumFractionDigits: 0,
	});

	return (
		<div className={classNames(className, styles.tag)}>
			<div className={styles.amount}>{formattedAmount}</div>
			<div className={styles.badge} />
		</div>
	);
};
