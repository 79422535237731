import { CSSProperties } from 'react';

import styles from './svg.module.scss';

interface SvgProps {
	id: string;
	className?: string;
	style?: CSSProperties;
}

export const Svg = ({ id, ...props }: SvgProps) => {
	const href = `/svgs/sprite.svg#${id}`;

	return (
		<svg className={styles.svg} xmlns="http://www.w3.org/2000/svg" {...props}>
			<use href={href} />
		</svg>
	);
};
