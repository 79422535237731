import classNames from 'classnames';

import { ClippedImageRecord } from '@interfaces';

import styles from './clipped-image.module.scss';

interface ClippedImageProps extends ClippedImageRecord {
	loading?: 'eager' | 'lazy';
	sizes?: string;
	hasShapeOutside?: boolean;
	className?: string;
}

export const ClippedImage = ({
	image,
	clipPath,
	loading = 'lazy',
	sizes = '100vw',
	hasShapeOutside = false,
	className,
}: ClippedImageProps) => {
	if (!image) return null;

	const style =
		clipPath !== 'none'
			? ({ '--clip-path': clipPath } as React.CSSProperties)
			: undefined;

	return (
		<img
			alt={image.alt || ''}
			className={classNames(className, styles['clipped-image'], {
				[styles['clipped-image__has-shape-outside']]: hasShapeOutside,
			})}
			height={image.height}
			loading={loading}
			sizes={sizes}
			src={image.responsiveImage.src}
			srcSet={image.responsiveImage.srcSet}
			style={style}
			width={image.width}
		/>
	);
};
