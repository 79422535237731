import React from 'react';
import classNames from 'classnames';

interface ButtonAnchorProps
	extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
	href: string;
	className?: string;
	isElsewherePresents?: boolean;
}

export const ButtonAnchor = ({
	className,
	children,
	isElsewherePresents,
	href,
	...buttonAnchorProps
}: ButtonAnchorProps) => {
	return (
		<a
			className={classNames(className, 'button', {
				'button--elsewhere-presents': isElsewherePresents,
			})}
			href={href}
			{...buttonAnchorProps}
		>
			{children}
		</a>
	);
};
