import { CURRENCY, LOCALE } from '@data/constants';

interface FormatPriceParams {
	cents: number;
	maximumFractionDigits?: number;
	minimumFractionDigits?: number;
}

export const formatPrice = ({
	cents,
	maximumFractionDigits = 2,
	minimumFractionDigits = 2,
}: FormatPriceParams): string => {
	return new Intl.NumberFormat(LOCALE, {
		style: 'currency',
		currency: CURRENCY,
		// With a `maximumFractionDigit` of `0` we can remove the fraction and display a price like `$20`.
		// Ideally we would be using `trailingZeroDisplay: 'lessPrecision'` for this but support is unclear.
		maximumFractionDigits,
		minimumFractionDigits,
	}).format(cents / 100);
};

export const formatPriceWithoutCurrency = ({
	cents,
	maximumFractionDigits = 2,
	minimumFractionDigits = 2,
}: FormatPriceParams): string => {
	return new Intl.NumberFormat(LOCALE, {
		style: 'decimal',
		maximumFractionDigits,
		minimumFractionDigits,
	}).format(cents / 100);
};
