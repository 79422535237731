import {
	createContext,
	Dispatch,
	ReactNode,
	SetStateAction,
	useContext,
	useEffect,
	useState,
} from 'react';
import {
	User as AuthUser,
	useSessionContext,
	useUser as useAuthUser,
} from '@supabase/auth-helpers-react';

import { handleError } from '@common/utils/handleError';
import { getUserRecord } from '@data/supabase/client';
import { getSubscription } from '@data/supabase/shared';
import {
	SupabaseSubscriptionRecordWithPrices,
	SupabaseUserRecord,
} from '@interfaces';

type UserContextType = {
	accessToken: string | null;
	authUser: AuthUser | null;
	userRecord: SupabaseUserRecord | null;
	setUserRecord: Dispatch<SetStateAction<SupabaseUserRecord | null>>;
	isLoading: boolean;
	subscription?: SupabaseSubscriptionRecordWithPrices | null;
};

export const UserContext = createContext<UserContextType | undefined>(
	undefined,
);

export interface UserContextProviderProps {
	children: ReactNode;
}

export const UserContextProvider = ({ children }: UserContextProviderProps) => {
	const [isLoadingData, setIsLoadingData] = useState(false);
	const [userRecord, setUserRecord] = useState<SupabaseUserRecord | null>(null);
	const [subscription, setSubscription] = useState<
		SupabaseSubscriptionRecordWithPrices | null | undefined
	>(undefined);

	const {
		session,
		isLoading: isLoadingAuthUser,
		supabaseClient,
	} = useSessionContext();
	const authUser = useAuthUser();
	const accessToken = session?.access_token || null;

	useEffect(() => {
		if (authUser && !isLoadingData && !userRecord) {
			setIsLoadingData(true);

			Promise.allSettled([
				getUserRecord(),
				getSubscription({ supabaseClient }),
			]).then(([userRecordPromise, subscriptionPromise]) => {
				if (userRecordPromise.status === 'fulfilled') {
					setUserRecord(userRecordPromise.value.data);
				} else {
					handleError(userRecordPromise.reason);
				}

				if (subscriptionPromise.status === 'fulfilled') {
					setSubscription(subscriptionPromise.value);
				} else {
					handleError(subscriptionPromise.reason);
				}

				setIsLoadingData(false);
			});
		} else if (!authUser && !isLoadingAuthUser && !isLoadingData) {
			setUserRecord(null);
			setSubscription(null);
		}
	}, [authUser, isLoadingAuthUser]);

	const value = {
		accessToken,
		authUser,
		userRecord,
		setUserRecord,
		isLoading: isLoadingAuthUser || isLoadingData,
		subscription,
	};

	return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => {
	const context = useContext(UserContext);

	if (context === undefined) {
		throw new Error('useUser must be used within a UserContextProvider.');
	}

	return context;
};
