import { ShopifyImage } from 'src/interfaces/shopify';

// https://shopify.dev/api/liquid/filters/url-filters#parameters
interface ShopImageOptions {
	width?: number;
	height?: number;
	format?: 'jpg' | 'pjpg';
	pad_color?: string;
}

const defaultSrcSetWidths = [400, 800, 1200, 1600, 2000];

const getSrc = (image: ShopifyImage, options?: ShopImageOptions) => {
	const url = new URL(image.url);

	if (options) {
		Object.keys(options).forEach((key) => {
			const value = String(options[key as keyof ShopImageOptions]);
			url.searchParams.append(key, value);
		});
	}

	return url.toString();
};

const getSrcSet = (
	image: ShopifyImage,
	widths: number[],
	options?: ShopImageOptions,
) => {
	return widths
		.map((width) => `${getSrc(image, { ...options, width })} ${width}w`)
		.join(',');
};

interface ShopImageProps {
	image: ShopifyImage;
	className?: string;
	loading?: 'eager' | 'lazy';
	sizes?: string;
	srcSetWidths?: number[];
	options?: ShopImageOptions;
}

export const ShopImage = ({
	className,
	loading = 'lazy',
	sizes = '100vw',
	image,
	srcSetWidths = defaultSrcSetWidths,
	options,
}: ShopImageProps) => {
	const { altText, width, height } = image;
	const src = getSrc(image, { width: defaultSrcSetWidths[0] });
	const srcSet = getSrcSet(image, srcSetWidths, options);

	return (
		<img
			alt={altText || ''}
			className={className}
			height={height}
			loading={loading}
			sizes={sizes}
			src={src}
			srcSet={srcSet}
			width={width}
		/>
	);
};
