import { ShopifyGetCollectionResponse } from 'src/interfaces/shopify';

import { seoFragment } from '@data/shopify/fragments';
import { shopifyClient } from '@data/shopify/shopifyClient';

export const getCollection = async (handle: string) => {
	return shopifyClient.request<ShopifyGetCollectionResponse>(
		`
			query getCollection($handle: String!) {
				collection(handle: $handle) {
					title
					handle
					description
					seo {
						...Seo
					}
				}
			}
			${seoFragment}
		`,
		{
			handle,
		},
	);
};
