export const PRODUCTION_WEBSITE_DOMAIN = `elsewhere.club`;
export const PRODUCTION_WEBSITE_URL = `https://www.${PRODUCTION_WEBSITE_DOMAIN}`;
export const CURRENCY = 'USD';
export const LOCALE = 'en-US';
export const TIMEZONE_NAME = 'America/New_York';
export const SIGN_IN_URL = '/sign-in';
export const FORGOT_PASSWORD_URL = '/forgot-password';
export const RESET_PASSWORD_URL = '/reset-password';
export const ACCOUNT_URL = '/my-account';
export const MY_EVENTS_URL = '/my-events';
export const MY_MEMBERSHIP_URL = '/my-membership';
export const PASSWORD_INPUT_ATTRIBUTES = {
	autoComplete: 'new-password',
	pattern: '.{6,}',
	required: true,
	title: '6 characters minimum',
	type: 'password',
};
export const MEMBERSHIP_URL = '/membership';
export const MEMBERS_URL = '/members';
export const SHOP_URL = '/shop';
export const ELSEWHERE_PRESENTS_URL = '/presents';
export const SHOP_DEFAULT_COLLECTION_HANDLE = 'all';
export const SHOP_COLLECTIONS_URL = `${SHOP_URL}/collections`;
export const SHOP_PRODUCTS_URL = `${SHOP_URL}/products`;
export const SHOP_METAFIELD_NAMESPACE = 'elsewhere';
export const SHOP_CART_STORAGE_KEY = 'cart';
export const SPACES_URL = '/spaces';
export const ZINE_URL = '/zine';
export const ZINE_TAG_URL = `${ZINE_URL}/tag`;
export const CART_LINE_COUNT = 60;
export const MEMBERSHIP_GIFT_TYPE = 'membership_gift';
export const MEMBERSHIP_GIFT_DELIVERY_TIME_UTC = '15:00:00';

export const DICE_API_ENDPOINT = 'https://events-api.dice.fm/v1/events';
export const DICE_PROMOTER_ID = '2601';
export const EVENT_INDEX_PAGE_SIZE = 36;
export const EVENT_INELIGIBLE_FOR_MEMBERS_DICE_TAG = 'ew:no-members';
export const OFF_SITE_DICE_TAG = 'ew:off-site';

export const DISCORD_API_ENDPOINT = 'https://discord.com/api/v10';
export const DISCORD_TOKEN_ENDPOINT = `${DISCORD_API_ENDPOINT}/oauth2/token`;
export const DISCORD_GET_USER_ENDPOINT = `${DISCORD_API_ENDPOINT}/users/@me`;
export const DISCORD_MEMBERS_ENDPOINT = `${DISCORD_API_ENDPOINT}/guilds/${process.env.NEXT_PUBLIC_DISCORD_GUILD_ID}/members`;
export const DISCORD_SERVER_URL = `https://discord.com/channels/${process.env.NEXT_PUBLIC_DISCORD_GUILD_ID}`;
export const DISCORD_REDIRECT_ENDPOINT = '/api/add-discord-user';
export const PAGINATION_ELLIPSIS = '...';
export const ZINE_POSTS_PER_PAGE = 17;

// https://docs.imgix.com/apis/rendering
export const DEFAULT_IMGIX_PARAMS = {
	auto: ['compress', 'format'],
	q: 80,
};

export const PAYWALLED_CONTENT_CLASSNAME = 'paywalled-content';

export const SLACK_ALERT_CHANNEL_EMAIL =
	'alerts-aaaaaahtryiuvbbudhoriqtrza@acb.slack.com';
export const DEV_TEAM_EMAIL = 'dev-team@acolorbright.com';

export const VERCEL_PROJECT_ID = 'prj_V8QLMib9BlZNtx644xDNw6MQ8FFC';
export const EVENT_DESCRIPTION_DELIMITER = '[extra-info]';

export const CONTACT_FORM_DEFAULT_ERROR_MESSAGE =
	'Something went wrong while trying to deliver your message. Please try again later';

export const MEMBERSHIP_TEASER_LINK_TEXT = 'Become a Member';
