import { ReactNode, useEffect, useState } from 'react';
import { A11yDialog } from 'react-a11y-dialog';
import A11yDialogInstance from 'a11y-dialog';
import classNameJoiner from 'classnames';

import { Svg } from '@components/svg/Svg';

import { setTabIndexes } from '@common/utils/a11y';
import { useScrollLock } from '@hooks/useScrollLock';

import styles from './dialog.module.scss';

type A11yDialogClassNames =
	| 'container'
	| 'overlay'
	| 'dialog'
	| 'title'
	| 'closeButton';

type DialogClassNames = A11yDialogClassNames | 'openButton';

interface DialogProps {
	classNames?: Partial<Record<DialogClassNames, string>>;
	closeButtonLabel: string;
	id: string;
	openButtonContent?: ReactNode;
	openButtonLabel?: string;
	title: string;
	showAfter?: number; // milliseconds after which the dialog is automatically shown
	onClose?: () => void; // function to be called when dialog closes
	children?: React.ReactNode;
	withScrollLock?: boolean;
}

export const Dialog = ({
	children,
	classNames = {},
	closeButtonLabel,
	id,
	openButtonContent,
	openButtonLabel,
	title,
	showAfter,
	onClose,
	withScrollLock = true,
}: DialogProps) => {
	const [dialogInstance, setDialogInstance] = useState<A11yDialogInstance>();
	const { lockScroll, unlockScroll } = useScrollLock();
	const closeDialogSelectors = 'a[href^="/"], button[data-sign-out]';
	const hasDialogClassName = 'has-dialog';

	useEffect(() => {
		if (!dialogInstance) {
			return;
		}

		const dialogElement = document.getElementById(id);

		if (dialogElement) {
			setTabIndexes(dialogElement, -1);
		}
	}, [dialogInstance]);

	const openDialog = () => dialogInstance?.show();
	const closeDialog = () => dialogInstance?.hide();

	const onShowDialog = ({ target }: Event) => {
		if (!(target instanceof Element)) return;

		const dialogElement = target;

		document.body.classList.add(hasDialogClassName);

		const closeDialogElements =
			dialogElement.querySelectorAll(closeDialogSelectors);

		setTabIndexes(dialogElement, 0);

		closeDialogElements.forEach((closeDialogElement) => {
			closeDialogElement.addEventListener('click', closeDialog);
		});

		if (withScrollLock) {
			lockScroll();
		}
	};

	const onHideDialog = ({ target }: Event) => {
		if (!(target instanceof Element)) return;

		const dialogElement = target;

		document.body.classList.remove(hasDialogClassName);

		setTabIndexes(dialogElement, -1);
		const closeDialogElements =
			dialogElement.querySelectorAll(closeDialogSelectors);

		closeDialogElements.forEach((closeDialogElement) => {
			closeDialogElement.removeEventListener('click', closeDialog);
		});

		if (withScrollLock) {
			unlockScroll();
		}

		if (onClose) {
			onClose();
		}
	};

	const onDestroyDialog = () => {
		document.body.classList.remove(hasDialogClassName);

		unlockScroll();
	};

	useEffect(() => {
		if (dialogInstance) {
			dialogInstance.on('show', onShowDialog);
			dialogInstance.on('hide', onHideDialog);
			dialogInstance.on('destroy', onDestroyDialog);

			return () => {
				dialogInstance.off('show', onShowDialog);
				dialogInstance.off('hide', onHideDialog);
				dialogInstance.on('destroy', onDestroyDialog);
			};
		}
	}, [dialogInstance]);

	// Automatically show dialog if `showAfter` is provided
	useEffect(() => {
		if (dialogInstance && typeof showAfter === 'number') {
			const showDialogTimer = setTimeout(() => {
				// Only show the dialog if there is no other dialog active
				if (!document.body.classList.contains(hasDialogClassName)) {
					dialogInstance.show();
				}
			}, showAfter);

			return () => clearTimeout(showDialogTimer);
		}
	}, [dialogInstance]);

	// Combine passed and local classNames
	const fullA11yDialogClassNames: Partial<
		Record<A11yDialogClassNames, string>
	> = (
		[
			'closeButton',
			'container',
			'dialog',
			'overlay',
			'title',
		] as A11yDialogClassNames[]
	).reduce(
		(accumulator, classNamekey) => ({
			...accumulator,
			[classNamekey]: classNameJoiner(
				classNames[classNamekey],
				styles[classNamekey],
			),
		}),
		{},
	);

	return (
		<>
			{openButtonContent ? (
				<button
					aria-label={openButtonLabel}
					className={classNames?.openButton}
					onClick={openDialog}
					type="button"
				>
					{openButtonContent}
				</button>
			) : null}
			<A11yDialog
				classNames={fullA11yDialogClassNames}
				closeButtonContent={<Svg id="close" />}
				closeButtonLabel={closeButtonLabel}
				closeButtonPosition="last"
				dialogRef={(instance: A11yDialogInstance | undefined) => {
					if (instance) {
						setDialogInstance(instance);
					}
				}}
				id={id}
				title={title}
			>
				{children}
			</A11yDialog>
		</>
	);
};
