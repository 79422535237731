import { ShopifyGetHandleListResponse } from 'src/interfaces/shopify';

import { shopifyClient } from '@data/shopify/shopifyClient';

interface GetHandleListOptions {
	count?: number;
	afterCursor?: string;
}

export const getHandleList = async (
	type: 'collections' | 'products',
	options?: GetHandleListOptions,
) => {
	const { count = 50, afterCursor = null } = options || {};

	return shopifyClient.request<ShopifyGetHandleListResponse>(
		`
			query getHandleList($count: Int!, $afterCursor: String) {
				${type}(first: $count, after: $afterCursor) {
					edges {
						cursor
						node {
							handle
						}
					}
				}
			}
		`,
		{
			count,
			afterCursor,
		},
	);
};
