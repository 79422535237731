import { useRef } from 'react';

import { isMobileWebkit } from '@common/utils/support';

export const useScrollLock = () => {
	const scrollYRef = useRef(0);
	const className = 'scroll-lock';
	const scrollOffsetProperty = '--scroll-lock-scroll-offset';
	const scrollbarGutterProperty = '--scroll-lock-scrollbar-gutter';

	const lockScroll = () => {
		const { body } = document;
		const { scrollY, innerWidth } = window;
		const scrollbarGutter = innerWidth - body.offsetWidth;

		scrollYRef.current = scrollY;

		if (isMobileWebkit() && scrollY > 0) {
			// On mobile webkit restore the scroll position using an offset.
			// This makes the use of transparency and animation possible.
			body.style.setProperty(scrollOffsetProperty, `-${scrollY / 16}rem`);
		}

		if (scrollbarGutter > 0) {
			// Set a scrollbar gutter property that prevents layout shift in the body.
			// This can also be used to keep other fixed position elements in place.
			body.style.setProperty(
				scrollbarGutterProperty,
				`${scrollbarGutter / 16}rem`,
			);
		}

		body.classList.add(className);
	};

	const unlockScroll = () => {
		const { body } = document;

		body.classList.remove(className);
		body.style.removeProperty(scrollOffsetProperty);
		body.style.removeProperty(scrollbarGutterProperty);

		window.scrollTo(0, scrollYRef.current);
	};

	return {
		lockScroll,
		unlockScroll,
	};
};
