import Link from 'next/link';
import classNames from 'classnames';

import { MembershipIllustration } from '@components/membershipIllustration/MembershipIllustration';
import { SplitHeading } from '@components/splitHeading/SplitHeading';

import { isGtagAvailable } from '@common/utils/tracking';
import { MembershipPopUpRecord } from '@interfaces';

import styles from './membership-pop-up-content.module.scss';

interface MembershipPopUpContentProps {
	popUp: MembershipPopUpRecord;
}

export const MembershipPopUpContent = ({
	popUp: { title, cta, text },
}: MembershipPopUpContentProps) => {
	const onClick = () => {
		if (isGtagAvailable()) {
			gtag('event', 'click', {
				event_category: 'Pop-up',
				event_label: 'Membership Pop-up CTA click',
			});
		}

		// show popup again starting tomorrow
		const newDate = new Date();
		newDate.setDate(newDate.getDate() + 1);
		window.localStorage.setItem(
			'show_membership_pop_up_from',
			newDate.toString(),
		);
	};

	const headingInsertAfter = [
		<div className={styles.illustrations}>
			<MembershipIllustration
				className={styles.illustration}
				key="2"
				// `max()` is the calculated result of the `fluid-length` function in the scss file
				sizes="max(12rem, 12rem + 12.0187793427vw - 2.8169014085rem)"
				tierLevel="2"
			/>
		</div>,
	];

	const headingInsertBefore = [
		null,
		<div className={styles.illustrations}>
			{['3', '1'].map((tier) => (
				<MembershipIllustration
					className={styles.illustration}
					key={tier}
					// `max()` is the calculated result of the `fluid-length` function in the scss file
					sizes="max(12rem, 12rem + 12.0187793427vw - 2.8169014085rem)"
					tierLevel={tier}
				/>
			))}
		</div>,
	];

	return (
		<div className={styles.content}>
			<SplitHeading
				centerOnMobile
				className={styles.heading}
				heading={title}
				insertAfter={headingInsertAfter}
				insertBefore={headingInsertBefore}
				tag="h2"
			/>
			<div
				className={classNames(
					styles.illustrations,
					styles.illustrations__mobile,
				)}
			>
				{['1', '2', '3'].map((tier) => (
					<MembershipIllustration
						className={styles.illustration}
						key={tier}
						// `max()` is the calculated result of the `fluid-length` function in the scss file
						sizes="max(12rem, 12rem + 12.0187793427vw - 2.8169014085rem)"
						tierLevel={tier}
					/>
				))}
			</div>
			<span className={styles.copy}>{text}</span>
			<Link
				className={classNames(styles.link, 'button')}
				href="/membership"
				onClick={onClick}
			>
				{cta}
			</Link>
		</div>
	);
};
